import { DayPickerBase } from 'react-day-picker';

import cx from 'clsx';

import styles from '../ui/DayPicker/DayPicker.module.scss';
import mainDayPickerStyles from 'react-day-picker/dist/style.module.css';

export const mixClassNames = (classNames: DayPickerBase['classNames']): DayPickerBase['classNames'] => ({
  ...classNames,
  ...mainDayPickerStyles,
  root: cx(styles['root--dayPicker'], classNames?.root, mainDayPickerStyles.root),
  head: cx(styles.head_cell, classNames?.head_cell, mainDayPickerStyles.head_cell),
  months: cx(styles.months, classNames?.months, mainDayPickerStyles.months),
  caption: cx(styles.caption, classNames?.caption, mainDayPickerStyles.caption),
  caption_label: cx(styles.caption_label, classNames?.caption_label, mainDayPickerStyles.caption_label),
  nav_button_previous: cx(styles.nav_button_previous, classNames?.nav_button_previous),
  nav_button_next: cx(styles.nav_button_next, classNames?.nav_button_next),
  nav_button: cx(styles.nav_button, classNames?.nav_button, mainDayPickerStyles.nav_button),
  nav_icon: cx(styles.nav_icon, classNames?.nav_icon),
  head_cell: cx(styles.head_cell, classNames?.head_cell, mainDayPickerStyles.head_cell),
  cell: cx(styles.cell, classNames?.cell, mainDayPickerStyles.cell),
  day: cx(styles.day, classNames?.day, mainDayPickerStyles.day),
  day_today: cx(styles.day_today, classNames?.day_today, mainDayPickerStyles.day_today),
});
