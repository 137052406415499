import React from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../../../types';

import styles from '../ModalDialog.module.scss';

export const ModalFooter: ComponentWithProps = ({ children, className, ...props }) => {
  return (
    <div {...props} className={cx(className, styles.modal__footer)}>
      {children}
    </div>
  );
};
