let matchesImpl: (node: Element, selector: string) => boolean;

export default function matches(node: Element, selector: string) {
  if (!matchesImpl) {
    // Allow to have browser specific fields and implementations
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const body: HTMLElement & { [key: string]: unknown } = document.body;
    const nativeMatch =
      body.matches ||
      body.matchesSelector ||
      body.webkitMatchesSelector ||
      body.mozMatchesSelector ||
      body.msMatchesSelector;

    matchesImpl = (n: Element, s: string) => nativeMatch.call(n, s);
  }

  return matchesImpl(node, selector);
}
