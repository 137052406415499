export const ONE_WEEK_TTL = 7 * 24 * 60 * 60 * 1000;

/**
 * Provides an API to store persistent and auto-invalidated cache
 */
export const LocalStorageCache = {
  set(variable: string, value: Record<string, unknown>, ttlInMilliseconds = ONE_WEEK_TTL) {
    const data = { value, expiresAt: ttlInMilliseconds ? new Date().getTime() + ttlInMilliseconds : null };

    localStorage.setItem(variable, JSON.stringify(data));
  },
  get(variable: string) {
    const data = localStorage.getItem(variable);

    if (!data) return null;

    const parsed = JSON.parse(data);

    if (typeof parsed !== 'object' || !parsed.value) {
      localStorage.removeItem(variable.toString());

      return null;
    }

    if (parsed !== null) {
      if (parsed.expiresAt !== null && parsed.expiresAt < new Date().getTime()) {
        localStorage.removeItem(variable.toString());
      } else {
        return parsed.value;
      }
    }
  },
  removeItem(variable: string) {
    localStorage.removeItem(variable);
  },
};
