// Credit https://github.com/react-bootstrap/react-overlays/blob/master/src/DropdownMenu.js

import { FunctionComponent, ReactElement, useContext, useRef } from 'react';

import { useCloseOnClickOutside } from '../../../hooks/useCloseOnClickOutside';

import { DropdownContext, DropdownContextType } from './DropdownContext';

type DropdownMenuRenderProps = {
  alignEnd: boolean | undefined;
  close(event: Event): void;
};

export type DropdownMenuOptions = {
  /**
   * Controls the visible state of the menu, generally this is
   * provided by the parent `Dropdown` component,
   * but may also be specified as a prop directly.
   */
  show?: boolean;

  /**
   * Aligns the dropdown menu to the 'end' of it's placement position.
   * Generally this is provided by the parent `Dropdown` component,
   * but may also be specified as a prop directly.
   */
  alignEnd?: boolean | undefined;

  /**
   * Override the default event used by RootCloseWrapper.
   */
  rootCloseEvent?: keyof HTMLElementEventMap;
};

export function useDropdownMenu(options: DropdownMenuOptions) {
  const context: DropdownContextType = useContext(DropdownContext);
  const hasShownRef = useRef(false);
  const { rootCloseEvent = 'click' } = options;

  const { show, alignEnd, direction, placement, toggle, setMenu, menuElement, toggleElement, hideOnClickInside } =
    context;

  if (show && !hasShownRef.current) {
    hasShownRef.current = true;
  }

  const handleClose = (e?: Event) => {
    if (!toggle) {
      return;
    }

    toggle(false, e);
  };

  const menuProps = {
    ref: setMenu,
    'aria-labelledby': toggleElement ? `${toggleElement.id}` : '',
  };
  const childArgs = {
    show,
    placement,
    direction,
    alignEnd,
    hasShown: hasShownRef.current,
    close: handleClose,
  };

  useCloseOnClickOutside(toggleElement, menuElement, handleClose, {
    closeTrigger: rootCloseEvent,
    disabled: false,
    hideOnClickInside,
  });

  return { ...childArgs, props: menuProps };
}

export type DropdownMenuProps = DropdownMenuOptions & {
  /**
   * A render prop that returns a Menu element. The `props`
   * argument should spread through to **a component that can accept a ref**.
   */
  children(renderProps: DropdownMenuRenderProps): ReactElement;
};

export const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({ children, ...options }) => {
  const args = useDropdownMenu(options);

  return args && args.hasShown ? children(args) : null;
};
