import React, { FunctionComponent } from 'react';

export const SliderArrow: FunctionComponent<{ rotated?: boolean; stroke: string }> = ({ stroke }) => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" stroke={stroke}>
    <defs>
      <style>{'.cls-1{ fill: none; stroke-miterlimit:10; stroke-width:1.5px; }'}</style>
    </defs>
    <circle className="cls-1" cx="20.04" cy="20" r="19" />
    <line className="cls-1" x1="13.04" x2="29.04" y1="20" y2="20" />
    <polyline className="cls-1" points="18.61 13.31 12.37 20.04 18.72 25.91" />
  </svg>
);
