import React, {
  CSSProperties,
  forwardRef,
  FunctionComponent,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, config, useSpring } from '@react-spring/web';

import cx from 'clsx';

import { Portal } from '../../Other/Portal';
import { calculatePlacement, Dropdown } from '../Core/Dropdown';
import { Direction, TriggerEvent } from '../Core/DropdownContext';
import { useDropdownMenu } from '../Core/DropdownMenu';
import { useDropdownToggle } from '../Core/DropdownToggle';

import styles from './SimpleDropdown.module.scss';

type ToggleProps = {
  /**
   * Component prop that renders toggle's content instead of title prop
   */
  toggleComponent?: (show: boolean | undefined) => ReactNode;
  toggleClassName?: string;
  tabIndex?: number;
  title?: ReactNode;
  disabled?: boolean;
  triggerEvent?: TriggerEvent;
  onClick?: MouseEventHandler;
  showDelay?: number;
};

const fast = { ...config.stiff, restSpeedThreshold: 1, restDisplacementThreshold: 0.01, duration: 300 };

const Toggle = forwardRef<HTMLDivElement, ToggleProps>(
  (
    { toggleClassName, tabIndex = 0, triggerEvent, disabled, showDelay = 300, toggleComponent, title, onClick },
    ref
  ) => {
    const [props, { show, toggle }] = useDropdownToggle();
    const timeout = useRef<number | null>(null);

    return (
      <div
        tabIndex={tabIndex}
        {...props}
        className={cx(styles.dropdown__toggle, toggleClassName, {
          [styles['dropdown__toggle--show']]: show && !disabled,
          [styles['dropdown__toggle--disabled']]: disabled,
        })}
        onClick={(e) => {
          if (!disabled && toggle) {
            toggle(!show, e);
          }

          onClick && onClick(e);
        }}
        onMouseEnter={() => {
          if (!disabled && triggerEvent === 'hover' && toggle) {
            if (showDelay) {
              timeout.current = window.setTimeout(() => {
                toggle(true);
              }, showDelay);
            } else {
              toggle(true);
            }
          }
        }}
        onMouseLeave={() => {
          if (showDelay && toggle && triggerEvent === 'hover') {
            toggle(false);
          }

          if (timeout.current) {
            window.clearTimeout(timeout.current);
          }
        }}
        data-testid="dropdown-toggle"
      >
        {title ? (
          <div ref={ref} className={styles['dropdown__toggle-content']}>
            {title}
            <FontAwesomeIcon
              icon="chevron-down"
              className={cx(styles['dropdown__toggle-chevron'], { rotated: Boolean(show && !disabled) })}
            />
          </div>
        ) : toggleComponent !== undefined ? (
          toggleComponent(show)
        ) : (
          <div />
        )}
      </div>
    );
  }
);

Toggle.displayName = 'Toggle';

type MenuProps = {
  role?: string;
  menuClassName?: string;
  menuStyle?: CSSProperties;
  defaultShow?: boolean;
  enablePortal?: boolean;
  triggerEvent?: TriggerEvent;
  onToggle?: (isOpen: boolean, event: SyntheticEvent) => void;
  dataTestId?: string;
  disableAnimation?: boolean;
};

const Menu: FunctionComponent<PropsWithChildren<MenuProps>> = ({
  role,
  menuStyle = {},
  menuClassName,
  enablePortal,
  children,
  triggerEvent,
  dataTestId,
  disableAnimation,
}) => {
  const { show, props, placement, close } = useDropdownMenu({});

  const { o, opacity } = useSpring<{ o: number; opacity: number }>({
    opacity: show ? 1 : 0,
    o: show ? 0 : 30,
    config: fast,
  });

  return (
    <animated.div
      {...props}
      style={{
        ...menuStyle,
        opacity: disableAnimation ? 1 : opacity,
        display: 'flex',
        visibility: disableAnimation ? 'visible' : show ? 'visible' : 'hidden',
        transform: disableAnimation ? 'none' : /right|left/.test(placement!) ? '' : o.to((deg) => `rotateX(${deg}deg)`),
      }}
      role={role}
      className={cx(styles.dropdown__menu, menuClassName, {
        [styles[`dropdown--${placement}`]]: enablePortal,
        [styles['dropdown__menu--show']]: show,
      })}
      data-dropdown-isopen={show}
      data-testid={dataTestId || 'dropdown-menu-content'}
      onMouseLeave={() => triggerEvent === 'hover' && close()}
    >
      <div className={styles.dropdown__holder}>
        <div className="dropdown__arrow" />
        <div className={cx('dropdown__menu-content', styles['dropdown__menu-content'])}>{children}</div>
      </div>
    </animated.div>
  );
};

export type SimpleDropdownProps = ToggleProps &
  MenuProps & {
    className?: string;
    /**
     * Toggle's content
     */
    title?: ReactNode;

    /**
     * Gives 100% width to dropdown menu relative to the toggle element
     */
    fullWidthMenu?: boolean;
    /**
     * Determines the direction and location of the Menu in relation to it's Toggle.
     */
    direction?: Direction;

    /**
     * Align the menu to the 'end' side of the placement side of the Dropdown toggle.
     * The default placement is `top-start` or `bottom-start`.
     */
    alignEnd?: boolean | undefined;

    /**
     * Controls the focus behavior for when the Dropdown is opened. Set to
     * `true` to always focus the first menu item, `keyboard` to focus only when
     * navigating via the keyboard, or `false` to disable completely
     *
     * The Default behavior is `false` **unless** the Menu has a `role="menu"`
     * where it will default to `keyboard` to match the recommended
     * [ARIA Authoring practices](https://www.w3.org/TR/wai-aria-practices-1.1/#menubutton).
     */
    focusFirstItemOnShow?: boolean | 'keyboard';

    /**
     * A css selector string that will return __focusable__ menu items.
     * Selectors should be relative to the menu component:
     * e.g. ` > li:not('.disabled')`
     */
    itemSelector?: string;

    /**
     * Whether or not the Dropdown is visible.
     * @controllable onToggle
     */
    show?: boolean;

    /**
     * Sets the initial show position of the Dropdown.
     */
    defaultShow?: boolean;

    /**
     * Enables moving the dropdown menu into the body component what
     * may be useful in case of overflow: hidden issues.
     * ! Attention: It will be broken when scrolling !
     * Todo: Add the scrolling behaviour fix
     */
    enablePortal?: boolean;

    /**
     * The node that will be used for injection of through the portal
     */
    rootNode?: string;

    /**
     * A callback fired when the Dropdown wishes to change visibility. Called with the requested
     * `show` value, the DOM event, and the source that fired it: 'click', 'keydown', 'rootClose' or 'select'.
     *
     * @controllable show
     */
    onToggle?: (isOpen: boolean, event: SyntheticEvent) => void;

    /**
     * The event that triggers the opening of the dropdown menu
     */
    triggerEvent?: TriggerEvent;

    /**
     * Prevent dropdown menu from being triggered
     */
    disable?: boolean;

    /**
     * Whether the menu part should be closed on click action inside
     */
    hideOnClickInside?: boolean;

    showDelay?: number;

    menuDataTestId?: string;
  };

export const SimpleDropdown = forwardRef<HTMLDivElement, PropsWithChildren<SimpleDropdownProps>>(
  (
    {
      title = '',
      className = '',
      enablePortal = false,
      rootNode = 'body',
      toggleClassName = '',
      toggleComponent,
      direction = 'bottom',
      disabled = false,
      triggerEvent = 'click',
      alignEnd,
      role = 'menu',
      menuClassName = '',
      menuStyle,
      children,
      hideOnClickInside = true,
      defaultShow = false,
      fullWidthMenu = false,
      showDelay = 300,
      tabIndex,
      onClick,
      menuDataTestId,
      disableAnimation,
      ...dropdownProps
    },
    ref
  ) => {
    const placement = calculatePlacement(direction, alignEnd);

    return (
      <div
        className={cx(styles.dropdown, className, styles[`dropdown--${placement}`], {
          [styles['dropdown--full-width']]: fullWidthMenu,
        })}
        data-testid="simple-dropdown-component"
      >
        <Dropdown
          enablePortal={enablePortal}
          defaultShow={defaultShow}
          hideOnClickInside={hideOnClickInside}
          direction={direction}
          alignEnd={alignEnd}
          triggerEvent={triggerEvent}
          {...dropdownProps}
        >
          {({ props }) => (
            <div {...props}>
              <Toggle
                ref={ref}
                tabIndex={tabIndex}
                toggleClassName={toggleClassName}
                title={title}
                showDelay={showDelay}
                toggleComponent={toggleComponent}
                disabled={disabled}
                triggerEvent={triggerEvent}
                onClick={onClick}
              />
              {!disabled ? (
                enablePortal ? (
                  <Portal rootNode={rootNode}>
                    <Menu
                      role={role}
                      menuClassName={menuClassName}
                      defaultShow={defaultShow}
                      enablePortal={enablePortal}
                      triggerEvent={triggerEvent}
                      menuStyle={menuStyle}
                      dataTestId={menuDataTestId}
                      disableAnimation={disableAnimation}
                    >
                      {children}
                    </Menu>
                  </Portal>
                ) : (
                  <Menu
                    role={role}
                    menuClassName={menuClassName}
                    menuStyle={menuStyle}
                    triggerEvent={triggerEvent}
                    defaultShow={defaultShow}
                    enablePortal={enablePortal}
                    disableAnimation={disableAnimation}
                    dataTestId={menuDataTestId}
                  >
                    {children}
                  </Menu>
                )
              ) : (
                <></>
              )}
            </div>
          )}
        </Dropdown>
      </div>
    );
  }
);

SimpleDropdown.displayName = 'SimpleDropdown';
