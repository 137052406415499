import React, { forwardRef } from 'react';

import cx from 'clsx';

import { DefaultProps } from '../../types';

export type ContainerProps = {
  fluid?: boolean;
};

export const Container = forwardRef<HTMLDivElement, DefaultProps<ContainerProps>>(
  ({ fluid = false, className, children, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cx(className, { container: !fluid, 'container-fluid': fluid })}
      data-testid="container-component"
    >
      {children}
    </div>
  )
);

Container.displayName = 'Container';
