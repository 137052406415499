import React, { ReactNode, useEffect, useRef, useState } from 'react';
import clamp from 'lodash/clamp';
import get from 'lodash/get';
import { animated, useSprings } from '@react-spring/web';
import useCallbackRef from '@restart/hooks/useCallbackRef';
import usePrevious from '@restart/hooks/usePrevious';
import { useDrag } from 'react-use-gesture';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';

import { SliderArrow } from './SliderArrow/SliderArrow';
import { SliderAnimationProps, SliderProps } from './Slider.utils';

import styles from './Slider.module.scss';

export const Slider: ComponentWithProps<SliderProps> = ({
  slidesPerPage = 1,
  animate3d = false,
  showArrows = false,
  showDots = false,
  showNeighbourSlides = false,
  defaultPage = 0,
  pageRotationThreshold = 1 / 3,
  arrowColor = '#000',
  children = [],
  className,
  activePage,
  forceRepaint,
  setActivePage,
  gesturesEnabled = true,
  onPageCompleted = () => null,
  customAnimationFunction,
  ...rest
}) => {
  // Initially it is undefined to make first touch a bit different from others
  const isVerticalScrollPrevented = useRef<boolean>(false);
  const innerWidth = useRef<number>(1024);
  const preventVerticalScrollOnDragging = (e: Event) => {
    if (isVerticalScrollPrevented.current && e.cancelable) e.preventDefault();
  };

  // Always fall back on approximate sizes of slider
  const containerWidth = useRef<number>(innerWidth.current);

  // Hook that allows to rerender component when it is needed due to imperative
  // nature of Slider changes (dragging should not rerender the whole thing)
  const [repaintCounter, updateCounter] = useState(0);

  // We use container ref to set the height and width of slider as it is not possible
  // with pure CSS because of absolutely-positioned page elements
  const [containerRef, attachRef] = useCallbackRef<HTMLDivElement>();

  // As it may become zero during transition
  containerWidth.current = get(containerRef, 'current.offsetWidth', innerWidth.current);

  // We use refs as it allows to prevent rerenders and improves the performance of Slider
  const activePageRef = useRef<number>(defaultPage);
  const previousPage = usePrevious<number>(activePageRef.current);

  // Dynamically define function of spring animation to compute it based on activePageRef and slidesPerPage
  const computeSpring = (i: number, activeId: number): SliderAnimationProps => {
    if (customAnimationFunction) return customAnimationFunction(i, activeId);

    return {
      x: (i - activeId) * (showNeighbourSlides ? containerWidth.current : innerWidth.current),
      scale: 1,
      opacity: 1,
      immediate: !containerRef,
      position: i === activeId ? 'relative' : 'absolute',
      // Keep 1 page from each side of page
      display: (activeId - 1) * slidesPerPage - 1 <= i || i <= (activeId + 2) * slidesPerPage ? 'flex' : 'none',
    };
  };

  const [props, set] = useSprings<SliderAnimationProps>(Math.ceil(children.length / slidesPerPage), (i: number) =>
    computeSpring(i, activePageRef.current)
  );

  const goToPage = (newIndex: number) => {
    activePageRef.current = newIndex;
    set.start((i: number) => computeSpring(i, activePageRef.current));

    if (setActivePage) {
      setActivePage(activePageRef.current);
    } else {
      onPageCompleted(activePageRef.current);
    }
  };

  let totalPages = Math.ceil(children.length / slidesPerPage);

  useEffect(() => {
    innerWidth.current = window.innerWidth;

    if (typeof activePage === 'number') {
      activePageRef.current = activePage;
    }

    totalPages = Math.ceil(children.length / slidesPerPage);
    // Reset but use Math.max in case if children array has 0 length
    goToPage(Math.max(Math.min(activePageRef.current, totalPages), 0));

    // Vertical scroll prevention if page is being dragged
    document.addEventListener('touchmove', preventVerticalScrollOnDragging, { passive: false });

    return () => {
      document.removeEventListener('touchmove', preventVerticalScrollOnDragging);
    };
  }, [activePage, slidesPerPage, forceRepaint, repaintCounter]);

  // This is gesture hook that creates handler for dragging event
  // It is not causing rerender and just animates the page's position
  const bind = useDrag(
    ({ down, movement: [xDelta, yDelta], direction: [xDir], distance, cancel, last }) => {
      isVerticalScrollPrevented.current =
        Boolean(isVerticalScrollPrevented.current) || Math.abs(xDelta) > Math.abs(yDelta);

      if (last) {
        isVerticalScrollPrevented.current = false;
      }

      // If progress of dragging of the page is not completed at least by threshold then
      // animation cancels and page returns back into its initial position
      if (isVerticalScrollPrevented.current && down && distance > containerWidth.current * pageRotationThreshold) {
        const potentialPage = activePageRef.current + (xDir > 0 ? -1 : 1);

        if (potentialPage < totalPages) {
          activePageRef.current = clamp(potentialPage, 0, totalPages);
        }

        if (cancel) {
          cancel();
        }
      }

      if (previousPage !== activePageRef.current && !children[activePageRef.current] && cancel) {
        activePageRef.current = previousPage || 0;
        cancel();
      }

      set.start((i: number) => {
        if (i < activePageRef.current - 1 || i > activePageRef.current + 1) {
          return { display: 'none' };
        }

        const x =
          (i - activePageRef.current) * (showNeighbourSlides ? containerWidth.current : innerWidth.current) +
          (down ? xDelta : 0);
        const scale = animate3d && down ? 1 - distance / containerWidth.current / 2 : 1;

        return { x, scale, display: 'flex' };
      });

      if (previousPage !== activePageRef.current) {
        if (setActivePage) {
          setActivePage(activePageRef.current);
        } else {
          onPageCompleted(activePageRef.current);
          updateCounter((state) => state + 1);
        }
      }
    },
    { enabled: gesturesEnabled }
  );

  return (
    <div
      {...rest}
      className={cx('slider', styles.slider, className, {
        [styles['slider--with-arrows']]: showArrows,
        [styles['slider--with-dots']]: showDots,
      })}
      data-testid="slider-component"
    >
      {props.map(({ x, position, display, scale, opacity }, i) => (
        <animated.div
          {...bind()}
          key={i + slidesPerPage}
          suppressHydrationWarning
          ref={(ref) => i === activePageRef.current && attachRef(ref)}
          style={{
            display,
            position,
            transform: x.to((value: number) => `translate3d(${value}px,0,0)`),
            opacity,
          }}
          className={cx('slider__page', styles['slider__slide-page'])}
        >
          {children.slice(i * slidesPerPage, (i + 1) * slidesPerPage).map((slide: ReactNode, slideIndex: number) => (
            <animated.div
              key={slideIndex}
              className={styles.slider__slide}
              style={{
                transform: scale.to((s: number) => `scale(${s})`),
                maxWidth: `${100 / slidesPerPage}%`,
              }}
            >
              {slide}
            </animated.div>
          ))}
        </animated.div>
      ))}
      {showArrows && (
        <div className={cx('slider__controls', styles.slider__controls)}>
          <button
            type="button"
            aria-label="Previous page"
            onClick={() => {
              goToPage(activePageRef.current - 1 >= 0 ? activePageRef.current - 1 : activePageRef.current);
              updateCounter((state) => state + 1);
            }}
            disabled={!activePageRef.current}
            data-testid="button-prev"
          >
            <SliderArrow stroke={arrowColor} />
          </button>
          <button
            type="button"
            aria-label="Next page"
            onClick={() => {
              goToPage(activePageRef.current < totalPages - 1 ? activePageRef.current + 1 : activePageRef.current);
              updateCounter((state) => state + 1);
            }}
            style={{ transform: 'rotate(180deg)' }}
            disabled={activePageRef.current === totalPages - 1}
            data-testid="button-next"
          >
            <SliderArrow stroke={arrowColor} />
          </button>
        </div>
      )}
      {showDots && (
        <ul className={cx('slider__dots', styles.slider__dots)} data-testid="slider-dots">
          {props.map((_, i) => (
            <li
              key={i}
              className={cx(styles['slider__dots-dot'], {
                [styles['slider__dots-dot--active']]: i === activePageRef.current,
              })}
              onClick={() => {
                goToPage(i);
                updateCounter((state) => state + 1);
              }}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
