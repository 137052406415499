import React, { ComponentType } from 'react';
import { Story } from '@storybook/react';

import { TFunction } from 'i18next';

export function StoryWithRoutes(
  StoryComponent: Story,
  getRoutes: (t: TFunction) => Array<{ path: string; component: ComponentType }>
) {
  return (
    <>
      {/*{getRoutes(t)*/}
      {/*  .filter(({ path }) => Boolean(path))*/}
      {/*  .map(({ path, component }) => (*/}
      {/*    <Route key={path} path={`/${path}`} component={component} />*/}
      {/*  ))}*/}
      {/*<StoryComponent />*/}
    </>
  );
}
