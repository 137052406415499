import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { ComponentWithProps } from '../../types';

export type PortalProps = {
  rootNode?: string;
  handleMounted?(): void;
  handleUnmounted?(): void;
  children?: JSX.Element | JSX.Element[] | Array<JSX.Element | null>;
};

export const Portal: ComponentWithProps<PortalProps> = ({ children, rootNode = '#modal-root' }) => {
  const node = useMemo(() => {
    if (typeof document !== 'undefined') {
      const mountingPoint = document.createElement('div');

      mountingPoint.classList.add('portal');

      return mountingPoint;
    }

    return null;
  }, []);

  useEffect(() => {
    const modalRoot = typeof document !== 'undefined' ? document.querySelector(rootNode) : null;

    if (modalRoot && node) {
      modalRoot.appendChild(node);
    }

    return () => {
      if (modalRoot && node) {
        modalRoot.removeChild(node);
      }
    };
  }, [node]);

  return node ? createPortal(children, node) : null;
};
