// Overwritten https://github.com/react-bootstrap/react-overlays/blob/master/src/Dropdown.js

import React, {
  DependencyList,
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import useCallbackRef from '@restart/hooks/useCallbackRef';
import useEventCallback from '@restart/hooks/useEventCallback';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import usePrevious from '@restart/hooks/usePrevious';

import { useUncontrolled } from 'uncontrollable';

import { noop } from '../../../utils';
import matches from '../../../utils/dom-helpers/matches';
import qsa from '../../../utils/dom-helpers/querySelectorAll';

import { Direction, DropdownContext, DropdownContextType, Placement, TriggerEvent } from './DropdownContext';
import { DropdownMenu } from './DropdownMenu';
import { DropdownToggle } from './DropdownToggle';
import relativePositionUpdater from './relativePositionUpdater';

export type DropdownRenderProps = {
  props: {
    onKeyDown(event: SyntheticEvent): void;
    onMouseLeave(event: SyntheticEvent): void;
  };
};

export type DropdownProps = {
  /**
   * A render prop that returns the root dropdown element. The `props`
   * argument should spread through to an element containing _both_ the
   * menu and toggle in order to handle keyboard events for focus management.
   */
  children(renderProps: DropdownRenderProps): ReactNode;

  /**
   * Determines the direction and location of the Menu in relation to it's Toggle.
   */
  direction?: Direction;

  /**
   * Align the menu to the 'end' side of the placement side of the Dropdown toggle.
   * The default placement is `top-start` or `bottom-start`.
   */
  alignEnd?: boolean | undefined;

  /**
   * Controls the focus behavior for when the Dropdown is opened. Set to
   * `true` to always focus the first menu item, `keyboard` to focus only when
   * navigating via the keyboard, or `false` to disable completely
   *
   * The Default behavior is `false` **unless** the Menu has a `role="menu"`
   * where it will default to `keyboard` to match the recommended
   * [ARIA Authoring practices](https://www.w3.org/TR/wai-aria-practices-1.1/#menubutton).
   */
  focusFirstItemOnShow?: boolean | 'keyboard';

  /**
   * Whether the menu part should be closed on click action inside
   */
  hideOnClickInside?: boolean;

  /**
   * A css selector string that will return __focusable__ menu items.
   * Selectors should be relative to the menu component:
   * e.g. ` > li:not('.disabled')`
   */
  itemSelector?: string;

  /**
   * Whether or not the Dropdown is visible.
   * @controllable onToggle
   */
  show?: boolean;

  /**
   * Sets the initial show position of the Dropdown.
   */
  defaultShow?: boolean;

  /**
   * Enables moving the dropdown menu into the body component what
   * may be useful in case of overflow: hidden issues.
   * ! Attention: It will be broken when scrolling !
   * Todo: Add the scrolling behaviour fix
   */
  enablePortal?: boolean;

  /**
   * A callback fired when the Dropdown wishes to change visibility. Called with the requested
   * `show` value, the DOM event, and the source that fired it: 'click', 'keydown', 'rootClose' or 'select'.
   *
   * @controllable show
   */
  onToggle?: (isOpen: boolean, event: SyntheticEvent) => void;

  /**
   * The event that triggers the opening of the dropdown menu
   */
  triggerEvent?: TriggerEvent;
};

export const calculatePlacement = (direction: Direction, alignEnd: boolean | undefined): Placement => {
  let placement: Placement = direction || 'bottom';

  // Align is defined
  if (placement !== 'left' && placement !== 'right' && (alignEnd === true || alignEnd === false))
    placement += alignEnd ? '-end' : '-start';

  return placement as Placement;
};

const keyboardEventHandler = (e: KeyboardEvent) => {
  if (e.key === 'Space') {
    e.preventDefault();
  }
};

/**
 * `Dropdown` is set of structural components for building, accessible dropdown menus with close-on-click,
 * keyboard navigation, and correct focus handling. As with all the react-overlay's
 * components its BYOS (bring your own styles). Dropdown is primarily
 * built from three base components, you should compose to build your Dropdowns.
 *
 * - `Dropdown`, which wraps the menu and toggle, and handles keyboard navigation
 * - `Dropdown.Toggle` generally a button that triggers the menu opening
 * - `Dropdown.Menu` The overlaid, menu, positioned to the toggle with PopperJs
 */
export const Dropdown: FunctionComponent<DropdownProps> & {
  Menu: typeof DropdownMenu;
  Toggle: typeof DropdownToggle;
} = ({
  direction = 'bottom',
  alignEnd,
  enablePortal = false,
  defaultShow = false,
  show: rawShow,
  onToggle: rawOnToggle = noop,
  itemSelector = 'ul > li',
  focusFirstItemOnShow = false,
  hideOnClickInside = false,
  triggerEvent,
  children,
}) => {
  /**
   * Returns a function that triggers a component update. the hook equivalent to
   * `this.forceUpdate()` in a class component. In most cases using a state value directly
   * is preferable but may be required in some advanced usages of refs for interop or
   * when direct DOM manipulation is required.
   */
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    forceUpdate();
  }, [children]);
  /**
   * Wrap this component to be able to use it uncontrolled via passing
   * only `defaultShow` and kinda forgot about it and let it be as it is.
   * Or we may pass `show` and `onToggle` props to let it be controlled from
   * outside.
   */
  const { show = false, onToggle } = useUncontrolled(
    { defaultShow, show: rawShow, onToggle: rawOnToggle },
    { show: 'onToggle' }
  );

  const [toggleElement, setToggle] = useCallbackRef<HTMLElement | null>();

  /**
   * We use normal refs instead of useCallbackRef in order to populate the
   * the value as quickly as possible, otherwise the effect to focus the element
   * may run before the state value is set
   */
  const menuRef = useRef<HTMLElement | null>(null);
  const menuElement = menuRef.current;

  const setMenu = useCallback(
    (ref: HTMLElement | null) => {
      menuRef.current = ref;
      // Ensure that a menu set triggers an update for consumers
      forceUpdate();
    },
    [forceUpdate] as DependencyList
  );

  const lastShow = usePrevious(show);
  const lastSourceEvent = useRef('');
  const focusInDropdown = useRef(false);

  const toggle = useCallback(
    (showDropdown: boolean, event?: Event | SyntheticEvent) => {
      onToggle(showDropdown, event as SyntheticEvent);

      if (showDropdown) {
        document.addEventListener('keydown', keyboardEventHandler);
      } else {
        document.removeEventListener('keydown', keyboardEventHandler);
      }
    },
    [onToggle, show] as DependencyList
  );

  const placement = calculatePlacement(direction, alignEnd);

  relativePositionUpdater(toggleElement || null, menuElement || null, {
    placement,
    eventsEnabled: show,
    enablePortal,
  });

  const context: DropdownContextType = useMemo(
    () => ({
      toggle,
      placement,
      direction,
      show,
      alignEnd,
      menuElement,
      toggleElement,
      setMenu,
      setToggle,
      triggerEvent,
      hideOnClickInside,
    }),
    [toggle, placement, direction, show, alignEnd, menuElement, toggleElement, setMenu, setToggle, hideOnClickInside]
  );

  if (menuElement && lastShow && !show) {
    focusInDropdown.current = menuElement.contains(document.activeElement);
  }

  const focusToggle = useEventCallback(() => {
    if (toggleElement && toggleElement.focus) {
      toggleElement.focus();
    }
  });

  const maybeFocusFirst = useEventCallback(() => {
    const type = lastSourceEvent.current;

    if (menuRef.current) {
      let focusType = focusFirstItemOnShow;

      if (focusType == null) {
        focusType = matches(menuRef.current, '[role=menu]') ? 'keyboard' : false;
      }

      if (focusType === false || (focusType === 'keyboard' && !/^key.+$/.test(type))) {
        return;
      }

      const firstElement = qsa(menuRef.current, itemSelector)[0];

      if (firstElement && firstElement.focus) firstElement.focus();
    }
  });

  useEffect(() => {
    if (show) maybeFocusFirst();
    else if (focusInDropdown.current) {
      focusInDropdown.current = false;
      focusToggle();
    }
    // only `show` should be changing
  }, [show, focusInDropdown, focusToggle, maybeFocusFirst]);

  useEffect(() => {
    lastSourceEvent.current = '';
  });

  const getNextFocusedChild = (current: HTMLElement, offset: number): HTMLElement | null => {
    if (!menuRef.current) return null;

    const items = qsa(menuRef.current, itemSelector);

    let index = items.indexOf(current) + offset;

    index = Math.max(0, Math.min(index, items.length));

    return items[index];
  };

  const handleKeyDown = (event: SyntheticEvent & KeyboardEvent): HTMLElement | null => {
    const { key } = event;
    const target = event.target as HTMLElement;

    // Second only to https://github.com/twbs/bootstrap/blob/8cfbf6933b8a0146ac3fbc369f19e520bd1ebdac/js/src/dropdown.js#L400
    // in inscrutability
    const isInput = /input|textarea/i.test(target.tagName);

    if (isInput && (key === ' ' || (key !== 'Escape' && menuRef.current && menuRef.current.contains(target)))) {
      return null;
    }

    lastSourceEvent.current = event.type;

    switch (key) {
      case 'ArrowUp': {
        if (!show) {
          toggle(true, event);
        } else {
          const next = getNextFocusedChild(target, -1);

          if (next && next.focus) next.focus();
        }

        return null;
      }

      case ' ':
      case 'Enter':
        event.preventDefault();

        toggle(!show, event);

        return null;

      case 'ArrowDown':
        event.preventDefault();

        if (!show) {
          toggle(true, event);
        } else {
          const next = getNextFocusedChild(target, 1);

          if (next && next.focus) next.focus();
        }

        return null;
      case 'Escape':
      case 'Tab':
        onToggle(false, event);

        return null;
      default:
    }

    return null;
  };

  return (
    <DropdownContext.Provider value={context}>
      {children({
        props: {
          onKeyDown: handleKeyDown,
          onMouseLeave: (e) => {
            if (triggerEvent === 'hover') {
              onToggle(false, e);
            }
          },
        },
      })}
    </DropdownContext.Provider>
  );
};

Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
