import React from 'react';
import { StoryFn } from '@storybook/react';

export function ComponentDecorator(StoryComponent: StoryFn) {
  return (
    <div className="m-4">
      <StoryComponent />
    </div>
  );
}
