import React from 'react';
import { LinkButton, NavBarItemType, NavBarOption, useMedia } from '@visalex/core';

import { useRouter } from 'next/router';
import { TFunction, useTranslation } from 'next-i18next';

const redirectUrl = process.env.VITE_LOGIN_REDIRECT || '';

export const NavBarRightSide = () => {
  const { t } = useTranslation();

  const isTablet = useMedia<boolean>(['(max-width: 1024px)'], [true], false);

  return (
    <>
      <LinkButton external outline className="mr-3 text-nowrap my-1" href={redirectUrl} size={isTablet ? 'sm' : ''}>
        {t('nav-bar.login')}
      </LinkButton>
      <LinkButton className="text-nowrap my-1" href="/free-consultation" size={isTablet ? 'sm' : ''}>
        {t('nav-bar.schedule-consultation')}
      </LinkButton>
    </>
  );
};

export const getGreenCardsOptions = (t: TFunction): NavBarOption[] => [
  {
    icon: '/icons/top-menu/icon-family.svg',
    text: t('nav-bar.green-cards-items.family-based.text'),
    postfix: t('nav-bar.green-cards-items.family-based.postfix'),
    link: '/green-cards/family-based',
  },
  {
    icon: '/icons/top-menu/icon-work.svg',
    text: t('nav-bar.green-cards-items.employment-based.text'),
    postfix: t('nav-bar.green-cards-items.employment-based.postfix'),
    link: '/green-cards/employment-based',
  },
  {
    icon: '/icons/top-menu/icon-investor.svg',
    text: t('nav-bar.green-cards-items.investment-based.text'),
    postfix: t('nav-bar.green-cards-items.investment-based.postfix'),
    link: '/green-cards/investment-based',
  },
];

export const getWorkVisaOptions = (t: TFunction): NavBarOption[] => [
  {
    icon: '/icons/top-menu/icon-investor.svg',
    text: t('nav-bar.work-visa-items.e.text'),
    postfix: t('nav-bar.work-visa-items.e.postfix'),
    link: '/visas/E2',
  },
  {
    icon: '/icons/top-menu/icon-speciality.svg',
    text: t('nav-bar.work-visa-items.h.text'),
    postfix: t('nav-bar.work-visa-items.h.postfix'),
    link: '/visas/H1',
  },
  {
    icon: '/icons/top-menu/icon-work.svg',
    text: t('nav-bar.work-visa-items.l.text'),
    postfix: t('nav-bar.work-visa-items.l.postfix'),
    link: '/visas/L1',
  },
  {
    icon: '/icons/top-menu/icon-star.svg',
    text: t('nav-bar.work-visa-items.o.text'),
    postfix: t('nav-bar.work-visa-items.o.postfix'),
    link: '/visas/O1',
  },
  {
    icon: '/icons/top-menu/icon-trophy.svg',
    text: t('nav-bar.work-visa-items.p.text'),
    postfix: t('nav-bar.work-visa-items.p.postfix'),
    link: '/visas/P1',
  },
];

export const getNavbarItems = (t: TFunction) => {
  const currentRoute = useRouter();

  const routes: NavBarItemType[] = [
    { link: '/visas', label: t('nav-bar.work-visas'), options: getWorkVisaOptions(t) },
    { link: '/green-cards', label: t('nav-bar.green-cards'), options: getGreenCardsOptions(t) },
    { link: '/visa-finder', label: t('nav-bar.visa-finder') },
    { link: '/platform', label: t('nav-bar.platform') },
    { link: '/why-visalex', label: t('nav-bar.why-visalex') },
  ];

  const activeRouteIndex = routes.findIndex((route) => route.link && currentRoute.pathname.includes(route.link));

  if (activeRouteIndex !== -1) {
    routes[activeRouteIndex].active = true;
  }

  return routes;
};
