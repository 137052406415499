import React, { FC } from 'react';
import Footer from '@/components/Footer/Footer';
import { getNavbarItems, NavBarRightSide } from '@/utils/NavBarElements';
import { Analytics } from '@vercel/analytics/react';
import { initIcons } from '@visalex/configs';
import { ActionBar, NavBar, NotificationProvider } from '@visalex/core';

import { NextComponentType, NextPageContext } from 'next';
import { AppContext, AppProps as NextAppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { appWithTranslation, useTranslation } from 'next-i18next';

import '@fortawesome/fontawesome-svg-core/styles.css';
import './main.scss';

const LocaleSelector = dynamic(import('@/components/LocaleSelector/LocaleSelector'), {
  ssr: false,
});

initIcons();

export type AppProps = NextAppProps & NextPageContext;

const App: NextComponentType<AppContext, unknown, AppProps> = ({ Component, pageProps, err }) => {
  const { t } = useTranslation();

  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <NotificationProvider>
      <Analytics />
      <ActionBar LocaleSelector={LocaleSelector} />
      <NavBar rightSide={<NavBarRightSide />} items={getNavbarItems(t)} isLoading={false} />
      <Component {...pageProps} err={err} />
      <Footer />
    </NotificationProvider>
  );
};

export default appWithTranslation(App as FC);
