import React from 'react';

import { ComponentWithProps } from '../../../types';
import { InlineLoader } from '../InlineLoader/InlineLoader';
import { SkeletonProps } from '../Skeleton/Skeleton';

export const FullPageLoader: ComponentWithProps<SkeletonProps> = ({ style, ...rest }) => (
  <InlineLoader
    {...rest}
    style={{
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      display: 'flex',
      left: 0,
      top: 0,
      ...style,
    }}
    customSpinnerStyle={{ width: 100 }}
  />
);
