let isNextEnv = true;

// Check if NextJs process object should be used
if (import.meta.env) {
  isNextEnv = false;
}

const envs = isNextEnv ? process.env : import.meta.env;

/**
 * General app settings
 */
export const appSettings = {
  isDevelopment: envs.DEV && envs.VITE_NODE_ENV !== 'test',
  isProduction: envs.PROD,
  isTesting: envs.VITE_NODE_ENV === 'test',
  isMarketingPages: envs.VITE_PORTAL === 'marketing',
  isClientPortal: envs.VITE_PORTAL === 'client',
  isInternalPortal: envs.VITE_PORTAL === 'internal',
  baseApiPath: `${envs.VITE_API_DOMAIN}${envs.VITE_API_PATH}`,
  tokenLocalStorageKey: '__VITE_TOKEN__',
  sentryDSN: 'https://b0b396ac0e2842c5a8270fab82eae42c@o453080.ingest.sentry.io/6611198',
  pagePreloadTimeout: 10000,
  formSavingInterval: 15 * 1000,
  dateFormat: 'MM/dd/yyyy',
  dateFormatBackend: 'yyyy-MM-dd',
  supportedLanguages: [
    { language: 'en', imageUrl: '/images/flags/en.svg' },
    { language: 'es', imageUrl: '/images/flags/es.svg' },
    { language: 'pt', imageUrl: '/images/flags/pt.svg' },
  ],
  // Iran, Libya, North Korea, Somalia, Syria, Venezuela, Yemen
  bannedCountries: ['IRN', 'LBY', 'PRK', 'SOM', 'SYR', 'VEN', 'YEM'],
  pwa: {
    appName: 'VisaLex',
    appDescription:
      'VisaLex is a leading provider of immigration solutions for individuals and businesses seeking immigrant and non-immigrant U.S. visas',
  },
  seo: {
    title: 'Your U.S. Immigration Goal – Our Mission',
    description:
      'VisaLex is a leading provider of immigration solutions for individuals and businesses seeking immigrant and non-immigrant U.S. visas.',
    phoneNumber: '+18448472539',
    phoneNumberFormatted: '+1 844 847 25 39',
  },
};

export const supportedLanguagesList = appSettings.supportedLanguages.map((el) => el.language);
