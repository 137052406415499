import React, { ComponentProps, forwardRef, Ref, useEffect, useMemo, useRef } from 'react';
import { useTransition } from '@react-spring/web';

import cx from 'clsx';
import { Assign } from 'utility-types';

import 'wicg-inert';

import { useCloseOnClickOutside } from '../../../hooks/useCloseOnClickOutside';
import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll';
import { DefaultProps } from '../../../types';
import { Backdrop } from '../../Other/Backdrop';
import { Portal, PortalProps } from '../../Other/Portal';

import styles from './BaseModal.module.scss';

export type ModalSize = 'sm' | 'lg' | 'xl' | 'full-height';
export type ModalPosition = 'left' | 'right' | 'center';

const isSSR = typeof document !== 'undefined';

let root: HTMLElement | null = null;

if (isSSR) {
  root = document.getElementById('root');

  if (!root) {
    root = document.getElementById('__next');
  }
}

export type BaseModalProps = Assign<
  PortalProps,
  {
    ref?: Ref<HTMLDivElement>;
    /** The state of modal */
    isOpen: boolean;

    /** Callback function that should switch modal to the closed state */
    onRequestClose(): void;

    /** Render a large, extra large or small modal. */
    size?: ModalSize;

    /** Specify whether to render backdrop behind modal. */
    showBackdrop?: boolean;

    /** Allows scrolling the modal body instead of the entire modal when overflowing. */
    scrollable?: boolean;

    /**
     * ReactElement selector that should become inert when modal is open.
     * Read more about `wicg-inert` in its documentation.
     * @default `#root`
     */
    inertContainerId?: string;

    position?: ModalPosition;

    /** Prevents outer content from being scrolled while modal is open */
    lockScrolling?: boolean;

    containerClassName?: string;

    /** Custom backdrop props */
    backdropProps?: ComponentProps<typeof Backdrop>;
  }
>;

export const BaseModal = forwardRef<HTMLDivElement, DefaultProps<BaseModalProps>>(
  (
    {
      isOpen,
      rootNode,
      showBackdrop = true,
      onRequestClose,
      children,
      className,
      position = 'center',
      size,
      inertContainerId = '#root',
      lockScrolling = false,
      containerClassName,
      backdropProps = {},
      scrollable,
      ...rest
    },
    ref
  ) => {
    const lastActiveElement = useRef<HTMLElement | null>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    const handleKeyDown = useMemo(
      () => (event: KeyboardEvent) => {
        if (event.key === 'Escape' && isOpen) {
          onRequestClose();
        }
      },
      [onRequestClose]
    );

    const backdropTransition = useTransition(isOpen, {
      background: 'rgba(0, 0, 0, 0)',
      opacity: showBackdrop ? 1 : 0,
      from: { background: 'rgba(0, 0, 0, 0)' },
      enter: { background: 'rgba(0, 0, 0, 0.5)', opacity: showBackdrop ? 1 : 0 },
      leave: { background: 'rgba(0, 0, 0, 0)' },
    });

    const body = isSSR ? document.body : null;

    body?.classList.toggle('modal-open', isOpen);
    body?.classList.toggle('show-scrollbar', isOpen && body?.scrollHeight > window.innerHeight);

    useCloseOnClickOutside(null, modalRef.current, onRequestClose, {
      disabled: !isOpen,
    });

    useLockBodyScroll(Boolean(isOpen) && lockScrolling);

    root?.toggleAttribute('inert', isOpen);

    // Prevent allowing focus on elements behind the modal
    useEffect(() => {
      if (root && inertContainerId !== '#root') {
        root = document.getElementById(inertContainerId);
      }

      if (isOpen) {
        if (isSSR) {
          lastActiveElement.current = document.activeElement as HTMLElement;
        }
      } else {
        // wait for inert to wear off then focus
        setTimeout(() => {
          if (lastActiveElement.current) {
            lastActiveElement.current.focus();
          }
        }, 0);
      }

      if (isOpen) window.addEventListener('keydown', handleKeyDown);
      else window.removeEventListener('keydown', handleKeyDown);

      return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isOpen]);

    return (
      <Portal rootNode={rootNode}>
        {backdropTransition((backdrop, item) =>
          item ? (
            <div
              ref={ref}
              className={cx(styles.modal__container, containerClassName, {
                ['overflow-hidden']: size === 'full-height',
              })}
              aria-modal="true"
              role="dialog"
            >
              <div
                ref={modalRef}
                className={cx(
                  styles.modal,
                  className,
                  styles[`modal-${position}`],
                  scrollable && styles['modal-scrollable'],
                  styles[`modal-${size}`]
                )}
                {...rest}
              >
                {children}
              </div>
              <Backdrop {...backdropProps} style={backdrop} onClick={onRequestClose} data-testid="backdrop-component" />
            </div>
          ) : null
        )}
      </Portal>
    );
  }
);

BaseModal.displayName = 'BaseModal';
