import React from 'react';

/**
 * When developing low level UI components, it is common to have to use a local ref but also support an external one using React.forwardRef.
 * Natively, React does not offer a way to set two refs inside the ref property. This is the goal of this small utility.
 * Today a ref can be a function or an object, tomorrow it could be another thing, who knows. This utility handles compatibility for you.
 *
 * @param refs - array of ref objects
 */
export function mergeRefs<T = any>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}
