import React, { FunctionComponent } from 'react';

// eslint-disable-next-line node/no-unpublished-import
import { ILoremIpsumParams, loremIpsum } from 'lorem-ipsum';

export type LoremProps = {
  units?: 'words' | 'word' | 'sentences' | 'sentence' | 'paragraphs' | 'paragraph';

  /**  Number of paragraphs to generate */
  count?: number;
  /**
   * The seed of the random number generator used for generating lorem text;
   * changing this to a different natural number generates a different text
   */
  seed?: number;

  /** Minimum words per sentence */
  sentenceLowerBound?: number;

  /** Maximum words per sentence */
  sentenceUpperBound?: number;

  /** Minimum sentences per paragraph */
  paragraphLowerBound?: number;

  /** Maximum sentences per paragraph */
  paragraphUpperBound?: number;
};

const random = (seed: number) => {
  return () => {
    seed = Math.sin(seed) * 1000;

    return seed - Math.floor(seed);
  };
};

export const Lorem: FunctionComponent<LoremProps> = ({
  count = 1,
  seed = 0,
  sentenceLowerBound = 1,
  sentenceUpperBound = 3,
  units = 'paragraphs',
  ...props
}) => {
  const loremProps: ILoremIpsumParams = {
    ...props,
    count,
    sentenceLowerBound,
    sentenceUpperBound,
    units,
    random: random(seed + 1),
  };

  return <span>{loremIpsum(loremProps)}</span>;
};
