import { useCallback, useEffect, useRef } from 'react';

import { contains, EventHandler, listen, noop } from '../utils';

const escapeKeyCode = 27;

function isLeftClickEvent(event: KeyboardEvent & { button: number }) {
  return event.button === 0;
}

function isModifiedEvent(event: KeyboardEvent) {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}

type UseColeOnClickOutsideOptions<K extends keyof HTMLElementEventMap = 'click'> = {
  disabled?: boolean;
  closeTrigger?: K;
  hideOnClickInside?: boolean;
};

/**
 * The `useCloseOnClickOutside` hook registers your callback on the document
 * when rendered. Powers the `<Overlay/>` component. This is used achieve modal
 * style behavior where your callback is triggered when the user tries to
 * interact with the rest of the document or hits the `esc` key.
 */
export function useCloseOnClickOutside<K extends keyof HTMLElementEventMap = 'click'>(
  triggerElement: HTMLElement | null,
  menuElement: HTMLElement | null,
  onRootClose: (e?: Event) => void,
  { disabled, closeTrigger, hideOnClickInside = false }: UseColeOnClickOutsideOptions<K> = {}
) {
  const preventMouseRootCloseRef = useRef(false);
  const onClose = onRootClose || noop;

  const handleMouseCapture = useCallback(
    (e: any) => {
      preventMouseRootCloseRef.current =
        !menuElement ||
        (menuElement && isModifiedEvent(e)) ||
        !isLeftClickEvent(e) ||
        Boolean(contains(menuElement, e.target));
    },
    [menuElement]
  );

  const handleMouse: EventHandler<K> = useCallback(
    (e) => {
      if (
        (!triggerElement || (triggerElement && !(e.target && contains(triggerElement, e.target as Element)))) &&
        (hideOnClickInside || !preventMouseRootCloseRef.current)
      ) {
        onClose(e);
      }
    },
    [triggerElement]
  );

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.keyCode === escapeKeyCode) {
      onClose(e);
    }
  }, []);

  useEffect(() => {
    if (disabled || menuElement == null) return noop;

    // Use capture for this listener so it fires before React's listener, to
    // avoid false positives in the contains() check below if the target DOM
    // element is removed in the React mouse callback.
    if (typeof document !== 'undefined' && document) {
      const removeMouseCaptureListener = closeTrigger
        ? listen(document.body, closeTrigger, handleMouseCapture, true)
        : noop;

      const removeMouseListener = closeTrigger ? listen(document.body, closeTrigger, handleMouse) : noop;
      const removeKeyupListener = listen(document.body, 'keyup', handleKeyUp);

      let mobileSafariHackListeners: (() => void)[] = [];

      if ('ontouchstart' in document.documentElement) {
        mobileSafariHackListeners = [].slice
          .call(document.body.children)
          .map((el: HTMLElement) => listen(el, 'mousemove', noop));
      }

      return () => {
        removeMouseCaptureListener();
        removeMouseListener();
        removeKeyupListener();
        mobileSafariHackListeners.forEach((remove) => remove());
      };
    }

    return noop;
  }, [menuElement, disabled, closeTrigger, handleMouseCapture, handleMouse, handleKeyUp]);
}
