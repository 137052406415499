import React, { ReactNode, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, config, useSpring } from '@react-spring/web';

import cx from 'clsx';

import { useMeasure } from '../../../hooks/useMeasure';
import { ComponentWithProps } from '../../../types';
import { AccordionClassNames } from '../Accordion';

import styles from '../Accordion.module.scss';

export type AccordionSectionWrapperProps = {
  isOpen?: boolean;
  /**
   * The index of the current node
   */
  index: number;
  header: ReactNode;
  actions?: ReactNode;
  showChevron?: boolean;
  classNames?: AccordionClassNames;
  onToggle: (index: number, wrapperEl: HTMLDivElement) => void;
};

export const AccordionSectionWrapper: ComponentWithProps<AccordionSectionWrapperProps> = ({
  header,
  isOpen,
  children,
  classNames = {},
  actions,
  showChevron = true,
  index,
  onToggle,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    ref,
    bounds: { height },
  } = useMeasure();

  const props = useSpring({
    immediate: true,
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
    config: config.default,
  });

  return (
    <div
      ref={wrapperRef}
      className={cx(styles.accordion__wrapper, classNames?.sectionWrapper, {
        [styles['accordion__wrapper--open']]: isOpen,
      })}
      data-id={index}
    >
      <div className={cx(styles.accordion__header, classNames?.sectionHeader)}>
        <div
          className={styles.accordion__header__content}
          onClick={() => wrapperRef.current && onToggle(index, wrapperRef.current)}
          data-testid="accordion-section-header"
        >
          {header}

          {showChevron && (
            <div>
              <FontAwesomeIcon icon="chevron-down" className={cx('accordion__chevron', styles.accordion__chevron)} />
            </div>
          )}
        </div>
        {actions}
      </div>
      <animated.div
        style={props}
        className={cx(styles['accordion__section-holder'], classNames?.sectionWrapperHolder)}
        data-testid="accordion-section-holder"
      >
        <animated.article ref={ref}>
          <div className={cx(classNames?.sectionContent)}>{children}</div>
        </animated.article>
      </animated.div>
    </div>
  );
};
