import React, { useState } from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { Skeleton } from '../Loaders/Skeleton/Skeleton';

import styles from './Avatar.module.scss';

export type AvatarProps = {
  width: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  avatarUrl?: string | null;
};

const colors = [
  '#1abc9c',
  '#2ecc71',
  '#3498db',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#95a5a6',
  '#f39c12',
  '#d35400',
  '#c0392b',
  '#bdc3c7',
  '#7f8c8d',
];

export const Avatar: ComponentWithProps<AvatarProps> = ({
  width,
  firstName,
  lastName,
  fullName,
  avatarUrl,
  className,
  ...rest
}) => {
  /** If image can not be loaded put initials instead of img*/
  const [isAvatarUrlError, setIsAvatarUrlError] = useState(false);
  const [isLoading, setLoading] = useState(true);

  let initials = '';
  let caption = '';

  if (firstName) {
    caption += `${firstName} `;
    initials += firstName.charAt(0).toUpperCase();
  }

  if (lastName) {
    caption += lastName;
    initials += lastName.charAt(0).toUpperCase();
  }

  if (fullName) {
    const parts = fullName.split(/\s/);
    const secondLetter = parts.length > 1 ? parts[1].charAt(0) : parts[0].charAt(1);

    caption = fullName;
    initials = `${parts[0].charAt(0)}${secondLetter}`.toUpperCase();
  }

  const charIndex = initials.charCodeAt(0) - 65;
  const colorIndex = charIndex % 19;
  const sharedStyles = {
    fontSize: `${width / 2.5}px`,
    minWidth: width,
    width,
    height: width,
    lineHeight: `${width + 2}px`,
  };
  const style = { backgroundColor: colors[colorIndex] };

  return (
    <div
      {...rest}
      className={cx(styles.avatar, className)}
      aria-label={caption}
      style={{ ...style, ...sharedStyles }}
      data-testid="avatar-component"
    >
      {!isAvatarUrlError && avatarUrl ? (
        <>
          {isLoading && <Skeleton width={width} height={width} className={styles.avatar__skeleton} />}
          <img
            data-testid="avatar-img"
            src={avatarUrl}
            alt="Avatar"
            width={width}
            height={width}
            onError={() => setIsAvatarUrlError(true)}
            onLoad={() => {
              setLoading(false);
            }}
          />
        </>
      ) : (
        <span className={styles.avatar__initials} data-testid="initials-element">
          {initials}
        </span>
      )}
    </div>
  );
};
