import React, { MouseEventHandler } from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { Col, ColProps } from '../Grid/Col';

import styles from './Card.module.scss';

export type CardProps = ColProps & {
  enabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const Card: ComponentWithProps<CardProps> = ({ className, enabled = true, onClick, children, ...rest }) => {
  return (
    <Col {...rest} className={cx(styles.card, className, { [styles['card--enabled']]: enabled })}>
      <div className={styles.card__content} onClick={onClick} data-testid="card-component">
        {children}
      </div>
    </Col>
  );
};
