export const snakeToCamel = (str: string) => {
  return str.toLowerCase().replace(/[-_][a-z]/g, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const toSnakeCase = (str: string) => {
  return str.replace(/([A-Z])/g, '_$1');
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Check if value is primitive
export const isPrimitive = (obj: Record<string, unknown>) => {
  return obj !== Object(obj);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepEqual = (obj1: Record<string, any>, obj2: Record<string, any>) => {
  if (obj1 === obj2)
    // it's just the same object. No need to compare.
    return true;

  if (isPrimitive(obj1) && isPrimitive(obj2))
    // compare primitives
    return obj1 === obj2;

  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

  // compare objects with same number of keys
  for (const key in obj1) {
    if (!(key in obj2) || !deepEqual(obj1[key], obj2[key])) return false; //other object doesn't have this prop
  }

  return true;
};

export const downloadBlob = (blob: Blob, name: string) => {
  const elem = document.createElement('a');

  // eslint-disable-next-line node/no-unsupported-features/node-builtins
  elem.href = URL.createObjectURL(blob);
  elem.download = name;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

export const downloadByUrl = async (url: string, name: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const blob = await response.blob();

  downloadBlob(new Blob([blob], { type: 'application/zip' }), name);
};

/**
 * Function that creates an array with incrementing numbers between the range
 * @param start - starting number of the array
 * @param end = last number of the array
 */
export const createArrayInRange = (start: number, end: number) => {
  return Array.from(Array(end - start + 1), (_, index) => start + index);
};

// https://developer.mozilla.org/en-US/docs/Web/API/Location/search
export const getSearchParamValue = (queryString: string, key: string) => {
  const params = new URLSearchParams(queryString);

  return params.get(key);
};
