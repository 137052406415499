import React, { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'visalex-router';

import cx from 'clsx';
import { Assign } from 'utility-types';

import qsa from '../../../utils/dom-helpers/querySelectorAll';

import styles from './SimpleDropdown.module.scss';

export type DropdownOptionType<Value = string> = {
  text: string;
  value: Value;
  link?: string;
  icon?: IconProp;
  before?: ReactNode;
  imageUrl?: string;
  selected?: boolean;
  isDivider?: boolean;
  dataTestid?: string;
};

export function DropdownOptionContent<Value = string>({
  text,
  before,
  imageUrl,
  icon,
  selected,
}: DropdownOptionType<Value>) {
  return (
    <div
      className={cx(
        'd-flex align-items-center',
        styles.dropdown__option,
        selected && styles['dropdown__option--selected']
      )}
    >
      {before}
      {imageUrl && <img src={imageUrl} alt={text} />}
      {icon && <FontAwesomeIcon icon={icon} />}
      {text}
    </div>
  );
}

export type DropdownOptionProps<Value = string> = DropdownOptionType<Value> & {
  onSelect?: (selectedOption: DropdownOptionType<Value>, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export function DropdownOption<Value = string>({ link, ...props }: DropdownOptionType<Value>) {
  return link ? (
    <Link to={link}>
      <DropdownOptionContent {...props} />
    </Link>
  ) : (
    <DropdownOptionContent {...props} />
  );
}

type DropdownOptionListProps<Value = string> = {
  show?: boolean;
  /**
   * Array of options to select from
   */
  options: DropdownOptionType<Value>[];
  /**
   * Component that is accessor of `DropdownOption` and being rendered as option
   */
  optionsComponent?: (props: DropdownOptionProps<Value>) => JSX.Element;
  /**
   * Optional callback for select event
   * @param selectedOption selected option
   */
  onSelect?: (selectedOption: DropdownOptionType<Value>, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export function DropdownOptionList<Value = string>({
  className,
  optionsComponent: Component = DropdownOption,
  options,
  onSelect,
  show = true,
  ...rest
}: Assign<DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>, DropdownOptionListProps<Value>>) {
  const listRef = useRef<HTMLUListElement>(null);

  const selected = options.findIndex((option) => option.selected);
  const lastSelected = useRef<number>(selected !== -1 ? selected : 0);

  useEffect(() => {
    if (show && listRef.current) {
      const items = qsa(listRef.current, 'li');

      requestAnimationFrame(() => {
        if (lastSelected.current < items.length) {
          items[lastSelected.current].focus();
        }
      });
    }
  }, [show]);

  const selectHandler = (
    option: DropdownOptionType<Value>,
    index: number,
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    if (onSelect) {
      onSelect(option, event);
      lastSelected.current = index;
    }
  };

  return (
    <ul {...rest} ref={listRef} className={className} data-testid="dropdown-option-list">
      {options.map((option, index) =>
        option.isDivider ? (
          option.text === '' ? (
            <div key="divider" className={styles.dropdown__divider} />
          ) : (
            <div key="divider" className={styles['dropdown__divider_with-text']}>
              <span className={styles.dropdown__divider_text}>{option.text}</span>
            </div>
          )
        ) : (
          <li
            key={option.text + index}
            tabIndex={0}
            onClick={(event) => {
              if (onSelect) {
                selectHandler(option, index, event);
              }
            }}
            onKeyDown={(e) =>
              e.key === 'Enter' &&
              selectHandler(option, index, e as unknown as React.MouseEvent<HTMLLIElement, MouseEvent>)
            }
            data-testid={option.dataTestid || 'dropdown-option'}
          >
            <Component {...option} onSelect={onSelect} />
          </li>
        )
      )}
    </ul>
  );
}
