import { createContext, SyntheticEvent } from 'react';

import { noop } from '../../../utils';

export type Direction = 'top' | 'left' | 'right' | 'bottom' | '';

export type Placement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

export type TriggerEvent = 'hover' | 'click';

export type DropdownContextType = {
  toggleElement: HTMLElement | null;
  setToggle: (popperNode: HTMLElement | null) => void;

  toggle: (value: boolean, event?: Event | SyntheticEvent) => void;
  onToggle?(): void;

  show: boolean;

  direction?: Direction;
  placement?: Placement;
  alignEnd?: boolean | undefined;
  hideOnClickInside?: boolean;
  triggerEvent?: TriggerEvent;

  menuElement: HTMLElement | null;
  setMenu: (ref: HTMLElement | null) => void;
};

// Creates context using React Context API
export const DropdownContext = createContext<DropdownContextType>({
  toggleElement: null,
  setToggle: noop,

  toggle: noop,
  onToggle: noop,

  // Whether or not the Dropdown is visible.
  show: false,

  triggerEvent: 'click',

  // Determines the direction and location of the Menu in relation to it's Toggle.
  direction: 'bottom',
  placement: 'bottom',
  alignEnd: undefined,
  hideOnClickInside: false,

  menuElement: null,
  setMenu: noop,
});
