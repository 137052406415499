import React, { forwardRef } from 'react';

import cx from 'clsx';

import { DefaultProps } from '../../../types';

import styles from './ButtonGroup.module.scss';

export type ButtonGroupProps = {
  className?: string;
  splitView?: boolean;
};

export const ButtonGroup = forwardRef<HTMLDivElement, DefaultProps<ButtonGroupProps>>(
  ({ children, className, splitView = false, ...props }, ref) => {
    return (
      <div
        className={cx(!splitView && styles['btn-group'], className)}
        role="group"
        {...props}
        ref={ref}
        data-testid="button-group-component"
      >
        {children}
      </div>
    );
  }
);

ButtonGroup.displayName = 'ButtonGroup';
