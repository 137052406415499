import React, { FunctionComponent } from 'react';
import { PieChart as MinimalPieChart, pieChartDefaultProps } from 'react-minimal-pie-chart';
import { PropsWithDefaults } from 'react-minimal-pie-chart/types/Chart/Chart';
import { BaseDataEntry } from 'react-minimal-pie-chart/types/commonTypes';

import cx from 'clsx';

import styles from './PieChart.module.scss';

export const PieChart: FunctionComponent<Partial<PropsWithDefaults<BaseDataEntry>>> = ({ className, ...rest }) => {
  return (
    <MinimalPieChart
      {...pieChartDefaultProps}
      {...rest}
      className={cx(styles['pie-chart'], className)}
      data-testid="pie-chart-component"
    />
  );
};
