import React from 'react';

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

// the type is needed to explicitly declare which strings are safe to pass
export type HeadingProps = React.HTMLAttributes<HTMLHeadingElement> & {
  headingLevel: HeadingLevel;
};

export const Heading = ({ headingLevel = 'p', children, className }: HeadingProps) => {
  const HeadingComponent = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(headingLevel, props, children);

  return <HeadingComponent className={className}>{children}</HeadingComponent>;
};
