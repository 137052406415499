import React from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../../types';
import { SliderProps } from '../../Slider/Slider.utils';
import { TabsProps } from '../Tabs';

import styles from '../Tabs.module.scss';

export const TabsContent: ComponentWithProps<TabsProps & Partial<Omit<SliderProps, 'children'>>> = ({
  activeTabId,
  tabs,
  className,
  setActiveTab,
  calculateTheCurrentTab: _,
}) => {
  return (
    <div className={cx(styles.tabs__container, className)} data-testid="tabs-wrapper">
      {tabs.map(
        ({ disabled, hidden, content, component: Component }, i) =>
          !disabled &&
          !hidden && (
            <div
              key={i}
              id={`tabpanel_${i}`}
              role="tabpanel"
              aria-labelledby={`tab_${i}`}
              onFocus={() => {
                if (activeTabId !== i && setActiveTab) setActiveTab(i);
              }}
              className={cx(styles.tabs__content, activeTabId !== i && styles['tabs__content--hidden'])}
              data-is-active={activeTabId === i}
            >
              {Component ? <Component /> : content}
            </div>
          )
      )}
    </div>
  );
};
