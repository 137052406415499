import { Placement } from './DropdownContext';

interface DOMRect {
  height: number;
  left: number;
  top: number;
  width: number;
}

export interface IUseRelativePositionOptions {
  placement: Placement;
  eventsEnabled: boolean;
  enablePortal: boolean;
}

/**
 * Position an element relative some toggle element using Menu.js
 */
export default function relativePositionUpdater(
  toggleElement: HTMLElement | null,
  menuElement: HTMLElement | null,
  { placement = 'bottom', eventsEnabled = false, enablePortal = false }: IUseRelativePositionOptions
) {
  if (!eventsEnabled || !toggleElement || !menuElement) {
    return undefined;
  }

  let left = 0;
  let top = 0;

  const togglePosition: DOMRect = !enablePortal
    ? {
        width: toggleElement.offsetWidth,
        height: toggleElement.offsetHeight,
        left: toggleElement.offsetLeft,
        top: toggleElement.offsetTop,
      }
    : toggleElement.getBoundingClientRect();

  const { width: menuWidth, height: menuHeight } = menuElement.getBoundingClientRect();

  switch (placement) {
    case 'top':
      left = togglePosition.left - menuWidth / 2 + togglePosition.width / 2;
      top = togglePosition.top - menuHeight;
      break;
    case 'top-start':
      left = togglePosition.left;
      top = togglePosition.top - menuHeight;
      break;
    case 'top-end':
      left = togglePosition.left + togglePosition.width - menuWidth;
      top = togglePosition.top - menuHeight;
      break;
    case 'left':
      left = togglePosition.left - menuWidth;
      top = togglePosition.top + togglePosition.height / 2 - menuHeight / 2;
      break;
    case 'right':
      left = togglePosition.left + togglePosition.width;
      top = togglePosition.top + togglePosition.height / 2 - menuHeight / 2;
      break;
    case 'bottom':
      left = togglePosition.left - menuWidth / 2 + togglePosition.width / 2;
      top = togglePosition.top + togglePosition.height;
      break;
    case 'bottom-start':
      left = togglePosition.left;
      top = togglePosition.top + togglePosition.height;
      break;
    case 'bottom-end':
      left = togglePosition.left + togglePosition.width - menuWidth;
      top = togglePosition.top + togglePosition.height;
      break;
    default:
      // if user set wrong placement, then use default 'top'
      left = togglePosition.left - menuWidth / 2 + togglePosition.width / 2;
      top = togglePosition.top - menuHeight;
      break;
  }

  if (enablePortal) top += window.scrollY;

  menuElement.style.top = `${top}px`;
  menuElement.style.left = `${left}px`;

  return { left, top };
}
