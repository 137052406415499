import React, { forwardRef, MouseEvent } from 'react';

import cx from 'clsx';

import { DefaultProps } from '../../types';

import { ButtonProps } from './utils/Button.types';
import { ButtonLoadingCircle } from './utils/Button.utils';

import styles from './Button.module.scss';

/**
 * The main button component. Used for popups, forms, etc.
 */
export const Button = forwardRef<HTMLButtonElement, DefaultProps<ButtonProps, HTMLButtonElement>>(
  (
    {
      className,
      theme = 'primary',
      size = '',
      loading = false,
      active = false,
      outline = false,
      block = false,
      icon = false,
      disabled = loading,
      type = 'button',
      onClick,
      ...rest
    },
    ref
  ) => {
    const classNames = cx(
      styles.btn,
      className,
      styles[`btn-${outline ? 'outline-' : ''}${theme !== 'default' ? theme : ''}`],
      active && styles.active,
      block && styles['btn-block'],
      icon && styles['btn-icon'],
      disabled && styles.disabled,
      size && styles[`btn-${size}`]
    );

    if (loading) {
      rest.children = ButtonLoadingCircle(rest);
    }

    return (
      <button
        data-testid="button-component"
        {...rest}
        type={type}
        ref={ref}
        className={classNames}
        data-active={active ? 'true' : 'false'}
        disabled={disabled}
        onClick={(e: MouseEvent<HTMLButtonElement>) => !disabled && onClick && onClick(e)}
      />
    );
  }
);
Button.displayName = 'Button';
