/**
 * Initializer for Sentry logging using dynamic module
 * loading used for optimizations
 */
import { appSettings } from './appSettings';

export const initSentry = async () => {
  if (!appSettings.isDevelopment && process.env.CURRENT_ENV !== 'local') {
    const [Sentry, { Integrations }] = await Promise.all([
      import(/* webpackChunkName: "sentry-react" */ '@sentry/react'),
      import(/* webpackChunkName: "sentry-tracing" */ '@sentry/tracing'),
    ]);

    Sentry.init({
      dsn: appSettings.sentryDSN,
      integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
};
