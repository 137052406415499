import { CSSObjectWithLabel } from 'react-select';

export const menuPortalProps = {
  menuPortalTarget: typeof document !== 'undefined' ? document.querySelector<HTMLElement>('#modal-root') || null : null,
  styles: {
    menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
  },
};
export const menuPortalPropsWithoutWidth = {
  menuPortalTarget: typeof document !== 'undefined' ? document.querySelector<HTMLElement>('#modal-root') : null,
  styles: {
    menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
  },
};
