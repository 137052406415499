import React, { useEffect } from 'react';
import get from 'lodash/get';
import { captureException } from '@sentry/react';
import { Link } from 'visalex-router';
import { useSelector } from 'react-redux';
import { useRouteError } from 'react-router-dom';

import { ComponentWithProps } from '../../types';
import { Button } from '../Button/Button';
import { Placeholder } from '../Placeholder/Placeholder';

import styles from './ErrorBoundary.module.scss';

export type ErrorBoundaryProps = {
  is404?: boolean;
};
export const ErrorBoundary: ComponentWithProps<ErrorBoundaryProps> = ({ className, is404 }) => {
  const error = useRouteError();
  const currentUser = useSelector((state) => get(state, 'users.currentUser'));

  console.error(error);

  useEffect(() => {
    if (!is404) {
      captureException(error, { extra: currentUser });
    }
  }, []);

  return (
    <Placeholder
      className={className}
      icon={
        <img
          className={styles.boundary__img}
          src={is404 ? '/images/undraw_taken.svg' : '/images/undraw_bug_fixing.svg'}
          alt="Error IMG"
        />
      }
    >
      <h4 className={styles.boundary__header}>Oops, {is404 ? "That page can't be found" : 'Something went wrong'}.</h4>
      {!is404 && (
        <>
          <p className={styles.boundary__content}>Please try again later.</p>
          <Button outline size="sm">
            <Link to="." reloadDocument style={{ textDecoration: 'none' }}>
              Try again
            </Link>
          </Button>
        </>
      )}
    </Placeholder>
  );
};
