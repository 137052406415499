import React, { FunctionComponent } from 'react';

import cx from 'clsx';

import { Link, LinkProps } from '../../Link/Link';

import styles from '../NavBar.module.scss';

export type NavBarItemProps = LinkProps & {
  active: boolean;
};

export const NavBarItem: FunctionComponent<NavBarItemProps> = ({ active, ...linkProps }) => (
  <div className={cx(styles.nav_bar__item, active && styles['nav_bar__item--active'])}>
    <Link {...linkProps} />
  </div>
);
