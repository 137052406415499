import React, { RefObject, useEffect, useRef } from 'react';
import { animated, SpringRef, useSpring } from '@react-spring/web';

import cx from 'clsx';

import { ComponentWithProps } from '../../../types';
import { Link } from '../../Link/Link';
import { TabsProps } from '../Tabs';

import { scrollToElement } from './TabBarUtils';

import styles from '../Tabs.module.scss';

type NavAnimationProps = { opacity: number; w: number; t: number };

let firstLoad = true;

function updateTab(activeTabId: number, set: SpringRef<NavAnimationProps>, containerRef: RefObject<HTMLUListElement>) {
  const activeTabElement =
    containerRef.current && containerRef.current.querySelector<HTMLLIElement>(`[aria-controls="tab_${activeTabId}"]`);

  set.start({
    t: activeTabElement ? activeTabElement.offsetLeft : 0,
    w: activeTabElement ? activeTabElement.offsetWidth : 0,
    immediate: firstLoad,
  });

  if (activeTabElement && containerRef.current) {
    scrollToElement(activeTabElement, containerRef.current);
  }
}

export const TabBar: ComponentWithProps<TabsProps, HTMLElement> = ({
  replace,
  activeTabId,
  setActiveTab,
  tabs,
  className,
  calculateTheCurrentTab,
}) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const [{ opacity, w, t }, set] = useSpring<NavAnimationProps>(() => ({ t: 0, w: 0, opacity: 0 }));

  useEffect(() => {
    updateTab(activeTabId, set, containerRef);
  }, [activeTabId]);

  useEffect(() => {
    let defaultIndex = activeTabId;

    if (calculateTheCurrentTab) defaultIndex = calculateTheCurrentTab(tabs);

    setTimeout(() => {
      if (setActiveTab && defaultIndex > -1) {
        setActiveTab(defaultIndex);
      }

      updateTab(defaultIndex, set, containerRef);

      set.start({ opacity: 1, immediate: false });

      firstLoad = false;
    }, 300);
  }, [tabs]);

  return (
    <ul ref={containerRef} className={cx(className, styles.tabs__container)} role="tablist">
      <animated.div
        style={{
          opacity,
          width: w.to((width) => `${width}px`),
          transform: t.to((transform) => `translateX(${transform}px)`),
        }}
        className={styles.tabs__underline}
        data-testid="tabs-underline"
      />

      {tabs.map((tab, i) => {
        const isActive = activeTabId === i;

        return (
          !tab.hidden && (
            <li
              key={`tab_${i}`}
              role="tab"
              id={`tab_${tab.id?.toLowerCase()}`}
              aria-controls={`tab_${i}`}
              aria-selected={isActive}
              tabIndex={!isActive && !setActiveTab ? -1 : undefined}
              className={cx(
                styles.tabs__item,
                isActive && styles['tabs__item--active'],
                tab.disabled && styles['tabs__item--disabled']
              )}
            >
              {tab.disabled ? (
                <span>{tab.title}</span>
              ) : (
                <Link
                  replace={replace}
                  href={tab.link}
                  styled={false}
                  onClick={() => setActiveTab && setActiveTab(i)}
                  onKeyUp={(e) => {
                    e.preventDefault();

                    if (e.key === 'Enter' || e.key === ' ') {
                      setActiveTab && setActiveTab(i);
                    }
                  }}
                >
                  {tab.title}
                </Link>
              )}
            </li>
          )
        );
      })}
    </ul>
  );
};
