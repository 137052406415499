import React, { ReactNode } from 'react';
import { Theme } from '@visalex/configs';

import cx from 'clsx';

import { MixProps } from '../../types';

import styles from './Callout.module.scss';

export type CalloutProps = MixProps<{
  icon?: ReactNode;
  iconSize?: 'small' | 'normal' | 'large';
  theme?: Theme;
  contentClassName?: string;
}>;

export const Callout = ({
  icon,
  iconSize = 'normal',
  theme = 'default',
  className,
  contentClassName,
  children,
  ...rest
}: CalloutProps) => (
  <div
    {...rest}
    role="alert"
    className={cx(className, styles.callout, styles[`callout--${theme}`])}
    data-testid="callout-component"
  >
    {icon && <div className={cx(styles.callout__icon, styles[`callout__icon--${iconSize}`])}>{icon}</div>}
    <div className={cx(contentClassName, styles.callout__content)}>{children}</div>
  </div>
);
