import React from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';

import styles from './Tag.module.scss';

export const Tag: ComponentWithProps = ({ children, className, ...props }) => {
  return (
    <div className={cx(className, styles.tag)} {...props}>
      {children}
    </div>
  );
};
