import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import cx from 'clsx';

import { useMedia } from '../../hooks/useMedia';
import { ComponentWithProps } from '../../types';

import { AccordionSectionProps } from './components/AccordionSection';
import { AccordionSectionWrapper } from './components/AccordionSectionWrapper';

import styles from './Accordion.module.scss';

export type AccordionClassNames = {
  accordion?: string;
  sectionWrapperHolder?: string;
  sectionHeader?: string;
  sectionWrapper?: string;
  sectionContent?: string;
};

export type AccordionProps = {
  /**
   * Configures if user is allowed to open multiple sections at once
   */
  allowMultipleOpen?: boolean;
  /**
   * Callback that is being triggered whenever section state changes
   * @param index of section
   * @param newState isOpen state
   */
  onToggle?: (index: number, newState: boolean) => void;
  scrollOnToggle?: boolean;
  /**
   * Allows to override the subset of classnames for sub-components
   */
  classNames?: AccordionClassNames;
  children: ReactElement<AccordionSectionProps>[];
};

export const Accordion: ComponentWithProps<AccordionProps> = ({
  className,
  children,
  allowMultipleOpen = false,
  classNames = {},
  onToggle,
  scrollOnToggle = false,
  ...rest
}) => {
  const [sectionsState, updateSectionsState] = useState<{ [index: string]: boolean }>({});
  const isMobile = useMedia<boolean>(['(max-width: 768px)'], [true], false);
  const sectionToggleHandler = useCallback(
    (index: number, wrapperEl: HTMLDivElement) => {
      const currentState = sectionsState[index];

      if (wrapperEl && scrollOnToggle && typeof window !== 'undefined') {
        let additionalOffset = 0;
        const boundingRect = wrapperEl.getBoundingClientRect();
        const openSections: NodeListOf<HTMLDivElement> = document.querySelectorAll<HTMLDivElement>(
          `.${styles['accordion__wrapper--open']}`
        );

        if (openSections) {
          openSections.forEach((section) => {
            if (Number(section.getAttribute('data-id')) < index) {
              additionalOffset += allowMultipleOpen ? 0 : section.offsetHeight;
            }
          });
        }

        window.scrollTo({
          top: boundingRect.top + window.pageYOffset + additionalOffset - (isMobile ? 75 : 150),
          left: 0,
          behavior: 'smooth',
        });
      }

      if (onToggle) onToggle(index, !currentState);

      if (allowMultipleOpen) updateSectionsState({ ...sectionsState, [index]: !currentState });
      else {
        const allClosed = Object.keys(sectionsState).reduce((acc, i) => {
          acc[i] = false;

          return acc;
        }, {} as Record<string, boolean>);

        updateSectionsState({ ...allClosed, [index]: !currentState });
      }
    },
    [sectionsState]
  );

  useEffect(() => {
    const openedSectionsInitial = { ...sectionsState };

    children.forEach((child) => {
      openedSectionsInitial[child.props.index] = Boolean(child.props.isDefaultOpen);
    });
    updateSectionsState(openedSectionsInitial);
  }, [allowMultipleOpen]);

  return (
    <div {...rest} className={cx(className, classNames.accordion, styles.accordion)} data-testid="accordion-component">
      {children.map(({ props: sectionProps, key }) => (
        <AccordionSectionWrapper
          key={key}
          {...sectionProps}
          isOpen={sectionProps.isOpen || sectionsState[sectionProps.index]}
          onToggle={sectionToggleHandler}
          classNames={classNames}
        />
      ))}
    </div>
  );
};
