import React, { Suspense, useEffect } from 'react';
import { StoryContext, StoryFn } from '@storybook/react';
// https://storybook.js.org/blog/internationalize-components-with-storybook/
// noinspection ES6UnusedImports
import { i18n, initIcons } from '@visalex/configs';
import { I18nextProvider } from 'react-i18next';

// Wrap your stories in the I18nextProvider component
export const withI18NextDecorator = (Story: StoryFn, context: StoryContext) => {
  const locale = context.parameters.locale || context.globals.locale;

  // When the locale global changes
  // Set the new locale in i18n
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return (
    // This catches the suspense from components not yet ready (still loading translations)
    // Alternative: set useSuspense to false on i18next.options.react when initializing i18next
    <Suspense fallback={<div>loading translations...</div>}>
      <I18nextProvider i18n={i18n}>
        <Story />
      </I18nextProvider>
    </Suspense>
  );
};
