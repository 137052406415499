import React, { FunctionComponent, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appSettings } from '@visalex/configs';

import cx from 'clsx';

import { ComponentWithProps } from '../../../types';
import { SimpleDropdown } from '../../Dropdown/SimpleDropdown/SimpleDropdown';
import { Link } from '../../Link/Link';
import { NavBarOption } from '../NavBar.utils';

import styles from '../NavBar.module.scss';

export type NavBarSelectProps = {
  id: string;
  label: ReactNode;
  url?: string;
  options: NavBarOption[];
  active?: boolean;
};

export const NavBarSelectContent: FunctionComponent<NavBarSelectProps> = ({ id, options }) => (
  <div className={styles.nav_bar__list}>
    {options.map(({ link, text, postfix, icon }, index) => (
      <Link
        key={`${id}-${index}`}
        href={link}
        className={cx(styles.nav_bar__item, appSettings.isMarketingPages && 'my-1')}
      >
        {icon && <img width={24} height={24} className={styles['nav_bar__select-icon']} src={icon} alt={text} />}
        <span className={styles['nav_bar__item-text-group']}>
          <span className={styles['nav_bar__item-main-text']}>{text}</span>
          {postfix && <span className={styles['nav_bar__item-postfix']}>{postfix}</span>}
        </span>
      </Link>
    ))}
  </div>
);

export const NavBarSelect: ComponentWithProps<NavBarSelectProps> = ({ className, ...props }) => {
  return (
    <SimpleDropdown
      alignEnd
      className={cx(className, styles.nav_bar__select)}
      direction="bottom"
      showDelay={0}
      triggerEvent={appSettings.isMarketingPages ? 'hover' : 'click'}
      title={!props.url ? <span>{props.label}</span> : undefined}
      toggleClassName={props.url ? styles['nav_bar__select-trigger__container'] : styles['nav_bar--no-decoration']}
      toggleComponent={
        props.url
          ? (show?: boolean) => (
              <Link
                href={props.url || ''}
                className={styles['nav_bar__select-trigger__item']}
                onClick={(e) => !appSettings.isMarketingPages && e.preventDefault()}
              >
                <span className={cx(props.active && styles['nav_bar__select-trigger__item--active'])}>
                  {props.label}
                </span>
                <FontAwesomeIcon icon="chevron-down" style={{ transform: `rotate(${show ? 180 : 0}deg)` }} />
              </Link>
            )
          : undefined
      }
    >
      <div className={styles['nav_bar__select-content']}>
        <div className={styles['nav_bar__select-arrow']} />
        <NavBarSelectContent {...props} />
      </div>
    </SimpleDropdown>
  );
};
