import React, { ReactNode } from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';

import styles from './Placeholder.module.scss';

export type PlaceholderProps = {
  icon?: ReactNode;
};

export const Placeholder: ComponentWithProps<PlaceholderProps> = ({ children, icon, className, ...rest }) => {
  return (
    <div {...rest} className={cx(styles.placeholder, className)}>
      {icon}
      <div className="text-muted">{children}</div>
    </div>
  );
};
