import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonProps } from './Button.types';

import styles from '../Button.module.scss';

export const ButtonLoadingCircle = (props: ButtonProps) => {
  return (
    <span className="position-relative d-flex">
      <FontAwesomeIcon
        className={styles['loading-circle']}
        icon="circle-notch"
        color="white"
        data-testid="loading-icon"
      />
      <span className={styles.transparent}>{props.children || 'Loading'}</span>
    </span>
  );
};
