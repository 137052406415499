import React from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../../types';
import { Col } from '../../Grid/Col';
import { Link } from '../../Link/Link';
import { NavBarItemType } from '../NavBar.utils';

import { NavBarItem } from './NavBarItem';
import { NavBarSelect, NavBarSelectContent } from './NavBarSelect';

import styles from '../NavBar.module.scss';

export type MenuItemsProps = {
  id: string;
  mobile?: boolean;
  items: NavBarItemType[];
  onItemClick?(): void;
};

export const MenuItems: ComponentWithProps<MenuItemsProps> = ({ id, items, mobile = false, onItemClick }) => {
  return (
    <div className={cx(styles.nav_bar__links, { 'd-none d-xl-flex': !mobile })}>
      <Col className="pl-0 d-lg-flex">
        {items.map((item, index) => (
          <div key={`div-${index}-${id}-${mobile ? 'mobile' : 'desktop'}`} onClick={onItemClick} className="d-flex">
            {item.options ? (
              mobile ? (
                <div className={cx('d-xl-none', styles.nav_bar__item)}>
                  <Link href={item.link || '/'} className={cx(item.active && styles['nav_bar__item--active'])}>
                    {item.label}
                  </Link>
                  <NavBarSelectContent
                    id={`${id}-inline-${mobile ? 'mobile' : 'desktop'}`}
                    label={item.label}
                    options={item.options}
                  />
                </div>
              ) : (
                <NavBarSelect
                  id={`${id}-${mobile ? 'mobile' : 'desktop'}`}
                  label={item.label}
                  url={item.link}
                  options={item.options}
                  active={Boolean(item.active)}
                />
              )
            ) : (
              <NavBarItem href={item.link || '/'} active={Boolean(item.active)}>
                {item.label}
              </NavBarItem>
            )}
          </div>
        ))}
      </Col>
    </div>
  );
};
