import React, { ComponentType, Dispatch, ReactChild, ReactNode, SetStateAction } from 'react';

import cx from 'clsx';

import { ComponentWithProps, MixProps } from '../../types';

import { TabBar } from './TabBar/TabBar';
import { TabsContent } from './TabsContent/TabsContent';

import styles from './Tabs.module.scss';

export type TabsProps = MixProps<{
  activeTabId: number;
  /**
   * react-router-dom link replace
   */
  replace?: boolean;
  /**
   * If there is validation in tabs you should provide setActiveTab
   */
  setActiveTab?: Dispatch<SetStateAction<number>>;
  tabs: Tab[];
  calculateTheCurrentTab?: (tabs: Tab[]) => number;
  disableAnimation?: boolean;
}>;

export type Tab = {
  title: ReactNode;
  link: string;
  id?: string;
  hidden?: boolean;
  disabled?: boolean;
  content?: ReactChild;
  component?: ComponentType;
};

export const Tabs: ComponentWithProps<TabsProps> = ({
  className,
  calculateTheCurrentTab,
  disableAnimation,
  ...rest
}) => {
  return (
    <div className={cx(className, styles.tabs)} data-testid="tabs-component">
      <TabBar {...rest} calculateTheCurrentTab={calculateTheCurrentTab} />
      <TabsContent {...rest} disableAnimation={disableAnimation} />
    </div>
  );
};
