import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faCheck,
  faCheckCircle as faCheckCircleSolid,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faComment,
  faEllipsisH,
  faEllipsisV,
  faExclamationTriangle as faExclamationTriangleSolid,
  faHashtag,
  faInfo as faInfoSolid,
  faInfoCircle as faInfoCircleSolid,
  faLock as faLockSolid,
  faPhoneAlt,
  faStar,
  faStarOfLife,
  faTimes as faTimesSolid,
  faTimesCircle as faTimesCircleSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBold,
  faCheckCircle as faCheckCircleLight,
  faCommentAltDots,
  faFileAlt as faFileAltLight,
  faFileCheck,
  faFileExclamation,
  faFileWord,
  faFolder,
  faGlobe as faGlobeLight,
  faGripLines,
  faH1,
  faH2,
  faH3,
  faH4,
  faImages,
  faInbox,
  faInfoCircle as faInfoCircleLight,
  faItalic,
  faLink as faLinkLight,
  faListOl,
  faListUl,
  faPaperclip,
  faPaperPlane,
  faPassport,
  faSave as faSaveLight,
  faSmile,
  faSms,
  faThumbtack,
  faTimes as faTimesLight,
  faTimesCircle as faTimesCircleLight,
  faUnderline,
  faUsersCog,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAddressCard,
  faAlarmClock,
  faAlignLeft,
  faArchive,
  faArrowLeft,
  faArrowRight,
  faArrows,
  faAt,
  faBell,
  faBriefcase,
  faCalendarAlt,
  faCheck as faCheckRegular,
  faCheckCircle,
  faCheckDouble,
  faChevronLeft,
  faChevronRight,
  faCircle as faCircleRegular,
  faClock,
  faCloud,
  faCloudUpload,
  faCog,
  faColumns,
  faCommentAltEdit,
  faCommentAltPlus,
  faCommentEdit,
  faCompressWide,
  faCrosshairs,
  faDollarSign,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisH as faEllipsisHRegular,
  faEmptySet,
  faExchange,
  faExclamationTriangle as faExclamationTriangleRegular,
  faExpandWide,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileCheck as faFileCheckRegular,
  faFileContract,
  faFilePlus,
  faFilter,
  faFolderOpen,
  faFolderPlus,
  faFolderUpload,
  faGlasses,
  faGlobe,
  faHistory,
  faHouseUser,
  faIdCard,
  faInfo,
  faInfoCircle,
  faLightbulb,
  faLink,
  faList,
  faLock,
  faMousePointer,
  faPen,
  faPencil,
  faPhone,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faQuestionCircle,
  faRedo,
  faSadTear,
  faSave,
  faSearch,
  faShieldCheck,
  faSignOut,
  faSlidersH,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortNumericUpAlt,
  faSpinner,
  faSquare,
  faStar as faStarRegular,
  faTable,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTimesHexagon,
  faTrashAlt,
  faUndo,
  faUnlink,
  faUpload,
  faUser,
  faUserCircle,
  faUserPlus,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';

export const iconLibrary = library.add(
  faInfo,
  faInfoCircleLight,
  faLock,
  faClock,
  faCloud,
  faCloudUpload,
  faArrowLeft,
  faPhoneAlt,
  faBars,
  faBell,
  faSearch,
  faTrashAlt,
  faUpload,
  faPlusCircle,
  faColumns,
  faFilter,
  faCheckDouble,
  faCheck,
  faCheckRegular,
  faExclamationTriangleSolid,
  faExclamationTriangleRegular,
  faGlobe,
  faEye,
  faEyeSlash,
  faStar,
  faStarRegular,
  faEllipsisV,
  faEllipsisH,
  faEllipsisHRegular,
  faGripLines,
  faExpandWide,
  faCompressWide,
  faCircle,
  faCircleRegular,
  faDotCircle,
  faCircleNotch,
  faCog,
  faRedo,
  faIdCard,
  faHashtag,
  faFolderPlus,
  faSave,
  faSaveLight,
  faSquare,
  faCheckSquare,
  faSearch,
  faEdit,
  faUndo,
  faUser,
  faPencil,
  faPlus,
  faMousePointer,
  faCrosshairs,
  faSignOut,
  faSadTear,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faLink,
  faLinkLight,
  faUnlink,
  faEmptySet,
  faExternalLinkAlt,
  faQuestionCircle,
  faSortAmountDown,
  faSortAmountDownAlt,
  faShieldCheck,
  faPowerOff,
  faUserPlus,
  faStarOfLife,
  faUserCircle,
  faFileContract,
  faGlasses,
  faCheckCircle,
  faCircle,
  faPlus,
  faLightbulb,
  faArrowRight,
  faFileAlt,
  faCommentAltEdit,
  faFilePlus,
  faInfoCircle,
  faInbox,
  faPassport,
  faFolder,
  faSms,
  faFileExclamation,
  faFileCheck,
  faFolderUpload,
  faCheckCircleLight,
  faTimes,
  faTimesSolid,
  faTimesLight,
  faTimesCircleLight,
  faGlobeLight,
  faCheckCircleSolid,
  faTimesCircleSolid,
  faInfoCircleSolid,
  faTimesCircle,
  faDownload,
  faFileWord,
  faFacebookF,
  faGoogle,
  faSpinner,
  faSortNumericUpAlt,
  faAt,
  faPhone,
  faDollarSign,
  faCalendarAlt,
  faAlignLeft,
  faHistory,
  faImages,
  faComment,
  faH1,
  faH2,
  faH3,
  faH4,
  faListUl,
  faListOl,
  faBold,
  faItalic,
  faUnderline,
  faAddressCard,
  faUnderline,
  faUserPlus,
  faExchange,
  faUserCircle,
  faUsersCog,
  faUnderline,
  faAddressCard,
  faCommentEdit,
  faThumbsUp,
  faList,
  faTable,
  faArrows,
  faFolderOpen,
  faCommentAltDots,
  faPen,
  faPaperPlane,
  faPaperclip,
  faThumbtack,
  faAlarmClock,
  faBriefcase,
  faHouseUser,
  faArchive,
  faTimesHexagon,
  faInfoSolid,
  faLockSolid,
  faFileCheckRegular,
  faSlidersH,
  faFileAltLight,
  faLink,
  faSmile,
  faCommentAltPlus,
  faUsers
);

export const initIcons = () => {
  iconLibrary;
};
