import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { useMedia } from '../../hooks/useMedia';

import styles from './Pagination.module.scss';

export type PaginationProps = Partial<ReactPaginateProps> & {
  pageCount: number;
};

export const Pagination: FunctionComponent<PaginationProps> = ({
  previousLabel,
  nextLabel,
  breakLabel,
  marginPagesDisplayed = 2,
  pageRangeDisplayed = 5,
  ...rest
}) => {
  const { t } = useTranslation();
  const isMobile = useMedia<boolean>(['(max-width: 768px)'], [true], false);

  if (isMobile) {
    marginPagesDisplayed = 1;
    pageRangeDisplayed = 1;
  }

  return (
    <ReactPaginate
      {...rest}
      previousLabel={
        previousLabel || <FontAwesomeIcon icon={['far', 'chevron-left']} title={t('common.pagination.previous')} />
      }
      nextLabel={nextLabel || <FontAwesomeIcon icon={['far', 'chevron-right']} title={t('common.pagination.next')} />}
      breakLabel={breakLabel || '...'}
      breakClassName={styles.pagination__break}
      containerClassName={styles.pagination__container}
      activeClassName={styles.pagination__active}
      disabledClassName={styles.pagination__disabled}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
    />
  );
};
