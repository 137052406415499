import React, { ReactNode } from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { Container } from '../Grid/Container';

import styles from './Pane.module.scss';

export type PaneProps = {
  header?: ReactNode;
  footer?: ReactNode;
  fluid?: boolean;
  contentClassName?: string | undefined;
};

export const Pane: ComponentWithProps<PaneProps> = ({
  fluid = false,
  children,
  header,
  className,
  contentClassName,
  footer,
  ...rest
}) => (
  <section {...rest} id="page-content" className={cx(styles.pane, className)}>
    <Container fluid={fluid} className={cx(styles.pane__container, fluid && styles['pane__container--fluid'], 'px-3')}>
      {header && <header className={styles.pane__header}>{header}</header>}
      <main className={cx(styles.pane__content, contentClassName)}>{children}</main>
      {footer && <div className={styles.pane__footer}>{footer}</div>}
    </Container>
  </section>
);
