import { useEffect, useRef } from 'react';

/**
 * https://stackoverflow.com/questions/39094138/reactjs-event-listener-beforeunload-added-but-not-removed
 * Provide any function to be fired before page close or reload.
 * @param fn
 */
export const useUnload = (fn: (ev: BeforeUnloadEvent) => void) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload: EventListener = (ev: BeforeUnloadEvent) => cb.current?.(ev);

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);
};
