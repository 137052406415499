import React, { FunctionComponent } from 'react';
import { appSettings } from '@visalex/configs';

import { ActionBarItem } from './ActionBarItem';

export const ActionBarPhone: FunctionComponent = () => {
  return (
    <ActionBarItem icon="phone-alt">
      <a href={`tel:${appSettings.seo.phoneNumber}`}>{appSettings.seo.phoneNumberFormatted}</a>
    </ActionBarItem>
  );
};
