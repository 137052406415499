import React from 'react';
import { StoryFn } from '@storybook/react';

export function FullpageDecorator(StoryComponent: StoryFn) {
  return (
    <div className="bg-white-blue">
      <StoryComponent />
      <style dangerouslySetInnerHTML={{ __html: '#page { padding: 0 }' }} />
    </div>
  );
}
