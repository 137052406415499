import React, { AnchorHTMLAttributes, FunctionComponent } from 'react';

import NextLink from 'next/link';
import { useRouter } from 'next/router';

type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  skipLocaleHandling?: boolean;
  locale?: string;
};

export const Link: FunctionComponent<LinkProps> = ({ children, skipLocaleHandling, locale: localeProps, ...rest }) => {
  const router = useRouter();
  const locale = localeProps || router.query.locale || '';

  let href = rest.href || router.asPath;

  if (href.indexOf('http') === 0) skipLocaleHandling = true;

  if (locale && !skipLocaleHandling) {
    href = href ? `/${locale}${href}` : router.pathname.replace('[locale]', locale as string);
  }

  return (
    <NextLink href={href} {...rest}>
      {children}
    </NextLink>
  );
};
