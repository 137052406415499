import React, { forwardRef } from 'react';

import cx from 'clsx';

import { DefaultProps } from '../../types';

export type RowProps = {
  noGutters?: boolean;
};

export const Row = forwardRef<HTMLDivElement, DefaultProps<RowProps>>(
  ({ noGutters = false, className, children, ...rest }, ref) => (
    <div {...rest} ref={ref} className={cx('row', className, { 'no-gutters': noGutters })}>
      {children}
    </div>
  )
);

Row.displayName = 'Row';
