import React, { useState } from 'react';

import { ComponentWithProps } from '../../types';

import { Heading, HeadingLevel } from './Heading';

import styles from './TruncatedText.module.scss';

export type TruncatedTextProps = {
  text: string;
  headingLevel?: HeadingLevel;
  numOfChars?: number;
  isFormattingEnabled?: boolean;
};

export const TruncatedText: ComponentWithProps<TruncatedTextProps> = ({
  text,
  headingLevel = 'p',
  numOfChars = 150,
  isFormattingEnabled = false,
}) => {
  const [showMore, setShowMore] = useState(false);

  const formattedText = showMore
    ? text
    : `${text.substring(0, numOfChars)}${text.length > numOfChars + 'Show more'.length ? '...' : ''}`;

  return (
    <Heading headingLevel={headingLevel} className={styles['truncated-text__header']}>
      <>
        {isFormattingEnabled ? (
          <span
            dangerouslySetInnerHTML={{
              __html: formattedText.replace(/\*.+?\*/g, (variable) => {
                return `<strong>${variable.replace(/\*/g, '')}</strong>`;
              }),
            }}
          />
        ) : (
          formattedText
        )}
        {text.length > numOfChars && (
          <span className={styles['truncated-text__toggle']} onClick={() => setShowMore((prev) => !prev)}>
            {showMore ? 'Show less' : 'Show more'}
          </span>
        )}
      </>
    </Heading>
  );
};
