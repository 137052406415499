import React, { ReactNode } from 'react';

import { ComponentWithProps } from '../../../types';

export type AccordionSectionProps = {
  actions?: ReactNode;
  header: ReactNode;
  isOpen?: boolean;
  showChevron?: boolean;
  isDefaultOpen?: boolean;
  index: number;
};

export const AccordionSection: ComponentWithProps<AccordionSectionProps> = ({ children }) => (
  <article>{children}</article>
);
