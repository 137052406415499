import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { Tooltip, TooltipProps } from '../Tooltip/Tooltip';

import styles from './InfoTooltip.module.scss';

type InfoTooltipProps = {
  icon?: IconProp;
  isDarkTheme?: boolean;
  iconClassName?: string;
} & Partial<TooltipProps>;

export const InfoTooltip: ComponentWithProps<InfoTooltipProps> = ({
  children,
  className,
  menuClassName,
  icon,
  isDarkTheme = true,
  iconClassName,
  ...rest
}) => {
  return (
    <Tooltip
      {...rest}
      enablePortal
      darkTheme={isDarkTheme ? true : undefined}
      tooltipContent={children}
      direction="top"
      className={cx(styles.tooltip__holder, styles.tooltip, className)}
      menuClassName={menuClassName}
    >
      <div className={iconClassName}>
        <FontAwesomeIcon icon={icon || ['far', 'info-circle']} className={styles.tooltip__caption} />
      </div>
    </Tooltip>
  );
};
