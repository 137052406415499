import { initReactI18next } from 'react-i18next';

import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

import { appSettings, supportedLanguagesList } from './appSettings';

export { i18n };
export const i18nInstance = !appSettings.isTesting
  ? i18n
      // load translation using xhr -> see /public/locales
      // learn more: https://github.com/i18next/i18next-xhr-backend
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      .init({
        backend: {
          loadPath: '/locales/{{lng}}/translation.json',

          // overrideMimeType sets request.overrideMimeType("application/json")
          overrideMimeType: true,

          withCredentials: true,
          // custom request headers sets request.setRequestHeader(key, value)
          customHeaders() {
            if (typeof localStorage === 'undefined') return {};

            return {
              Authorization: localStorage.getItem(appSettings.tokenLocalStorageKey),
            };
          },

          backends: [LocalStorageBackend],
          backendOptions: [
            {
              // prefix for stored languages
              prefix: 'i18next_',

              // expiration
              expirationTime: 7 * 24 * 60 * 60 * 1000,
            },
          ],
        },
        debug: false,
        defaultNS: 'translation',

        // fallback, can be a string or an array of namespaces
        fallbackNS: 'translation',
        fallbackLng: 'en',

        returnObjects: true,
      })
  : i18n.use(initReactI18next);

export const getLanguage = (): 'en' | 'es' | 'pt' => {
  // It may detect ru-RU or en-US
  let detectedLanguage = 'en';

  if (typeof window !== 'undefined') {
    detectedLanguage = window.localStorage.i18nextLng?.split('-')[0];
  }

  if (i18n.language) {
    detectedLanguage = i18n.language?.split('-')[0];
  }

  return supportedLanguagesList.includes(detectedLanguage) ? (detectedLanguage as 'en' | 'es' | 'pt') : 'en';
};

export const t = i18n.getFixedT(getLanguage());
export { Trans, useTranslation, withTranslation } from 'react-i18next';

const langToLocale = { en, pt, es };

export const getDateFnsLocale = () => {
  const currentLang = getLanguage();

  return langToLocale[currentLang] ? langToLocale[currentLang] : en;
};
