import React, { forwardRef, ReactNode, ReactNodeArray } from 'react';

import cx from 'clsx';

import { DefaultProps } from '../../types';
import { SimpleDropdown, SimpleDropdownProps } from '../Dropdown/SimpleDropdown/SimpleDropdown';

import styles from './Tooltip.module.scss';

export type TooltipProps = Omit<SimpleDropdownProps, 'children' | 'title'> & {
  /**
   * The internals of the tooltip. It may be a text or ReactNode
   */
  tooltipContent?: ReactNode;
  text?: ReactNode;
  width?: number;

  children?: ReactNode | ReactNodeArray;

  darkTheme?: boolean;
  onMouseLeave?(): void;
  onMouseEnter?(): void;
};

const TooltipContent = forwardRef<HTMLDivElement, DefaultProps<TooltipProps, HTMLDivElement>>(
  ({ direction, style, width = style?.width, text, tooltipContent }, ref) => (
    // TODO: consult about x-placement?
    // eslint-disable-next-line react/no-unknown-property
    <div ref={ref} style={{ ...style, width }} role="tooltip" x-placement={direction}>
      {text ? <span className={styles.tooltip__text}>{text}</span> : tooltipContent}
    </div>
  )
);

TooltipContent.displayName = 'TooltipContent';

export const Tooltip = forwardRef<HTMLDivElement, DefaultProps<TooltipProps, HTMLDivElement>>(
  (
    {
      children,
      triggerEvent = 'hover',
      direction = 'top',
      darkTheme = false,
      className,
      style,
      tooltipContent,
      ...props
    },
    ref
  ) => {
    return (
      <SimpleDropdown
        {...props}
        className={cx(className, styles.tooltip, styles[`tooltip-${direction}`], darkTheme && styles['tooltip-dark'])}
        triggerEvent={triggerEvent}
        menuClassName={cx(
          props.enablePortal && styles['tooltip--portaled'],
          styles[`dropdown--${direction}`],
          darkTheme && styles['tooltip-dark__menu'],
          props.menuClassName
        )}
        direction={direction}
        toggleComponent={(_show: boolean | undefined) => children}
      >
        <TooltipContent {...props} direction={direction} style={style} tooltipContent={tooltipContent} ref={ref} />
      </SimpleDropdown>
    );
  }
);

Tooltip.displayName = 'Tooltip';
