import React from 'react';

import { PaginatedResponse } from '../../../api';

import { Pagination, PaginationProps } from './Pagination';

export type PaginationControlProps<D extends Record<string, any>> = Partial<PaginationProps> & {
  data?: PaginatedResponse<D>;
  onPageNumberChange(newPage: number): void;
};

export function PaginationControl<D extends Record<string, any>>({
  data,
  onPageNumberChange,
  ...props
}: PaginationControlProps<D>) {
  const pageCount = data?.paging.totalNumberOfPages || 1;

  if (!data) {
    return null;
  }

  return (
    <Pagination
      {...props}
      forcePage={Number((data.paging.pageNumber && data.paging.pageNumber - 1) || 0)}
      pageCount={pageCount}
      onPageChange={({ selected }) => {
        // Set pageNumber only if its value is different from the default one
        if (selected + 1 !== data.paging.pageNumber) {
          onPageNumberChange(selected + 1);
        }
      }}
    />
  );
}
