import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, config, useTransition, UseTransitionProps } from '@react-spring/web';

import cx from 'clsx';
import { Assign } from 'utility-types';

import { ComponentWithProps } from '../../../types';
import { Button } from '../../Button/Button';
import { FocusLock } from '../../Other/FocusLock';
import { BaseModal, BaseModalProps } from '../BaseModal/BaseModal';

import styles from './ModalDialog.module.scss';

export type TransitionProps = Assign<CSSProperties, UseTransitionProps<boolean>>;

/** You may specify the extended classNames for each component of the modal */
export type ModalExtendedClassNames = {
  content?: string;
  header?: string;
};

export type ModalDialogProps = BaseModalProps & {
  title?: string;
  modalTransition?: TransitionProps;
  showCloseIcon?: boolean;
  extendedClassNames?: ModalExtendedClassNames;
  focusLockDisabled?: boolean;
  focusLockReturnFocus?: boolean;
};

export function useModalDialogTransition(isOpen: boolean, props: TransitionProps = {}) {
  return useTransition<boolean, { opacity: number; transition: string }>(isOpen, {
    from: { opacity: 0, transform: 'scale(0.9)' },
    enter: { opacity: 1, transform: 'scale(1)' },
    leave: { opacity: 0, transform: 'scale(0.8)' },
    config: config.stiff,
    ...props,
  });
}

export const ModalDialog: ComponentWithProps<ModalDialogProps> = ({
  children,
  isOpen,
  onRequestClose,
  title,
  modalTransition = {},
  focusLockDisabled,
  focusLockReturnFocus = true,
  autoFocus = true,
  showCloseIcon = true,
  extendedClassNames = {},
  ...props
}) => {
  const transition = useModalDialogTransition(isOpen, modalTransition);

  return (
    <BaseModal data-testid="modal-component" isOpen={isOpen} onRequestClose={onRequestClose} {...props}>
      {transition((transitionStyles, item) =>
        item ? (
          <animated.div
            key={String(item)}
            style={transitionStyles}
            role="document"
            className={cx(styles['modal-dialog'])}
            data-testid="modal-content"
          >
            <FocusLock
              disabled={focusLockDisabled}
              returnFocus={focusLockReturnFocus}
              autoFocus={autoFocus}
              className={extendedClassNames.content}
            >
              <div className={cx(styles['modal-dialog__header'], extendedClassNames.header)}>
                {title && <h3>{title}</h3>}
                {showCloseIcon && (
                  <Button
                    icon
                    theme="default"
                    aria-label="Close"
                    className={styles['modal-dialog__close']}
                    onClick={onRequestClose}
                  >
                    <FontAwesomeIcon aria-hidden="true" icon={['fal', 'times']} />
                  </Button>
                )}
              </div>
              {children}
            </FocusLock>
          </animated.div>
        ) : null
      )}
    </BaseModal>
  );
};

ModalDialog.displayName = 'ModalDialog';
