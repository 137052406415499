import React from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../../../types';

import styles from './DefaultStyledPopup.module.scss';

export type DefaultContentTypeProps = {
  title: string;
  text: string;
  caption?: string;
};

export const DefaultStyledPopup: ComponentWithProps<DefaultContentTypeProps> = ({
  className,
  title,
  text,
  caption,
}) => {
  return (
    <div className={cx(className, styles['default-content-type'])}>
      <h4>{title}</h4>
      <p>{text}</p>
      {caption ? <p className={styles['default-content-type__caption']}>{caption}</p> : null}
    </div>
  );
};
