import React, { createContext, FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, useTransition } from '@react-spring/web';
import { Theme } from '@visalex/configs';
import { Trans } from 'visalex-i18next';

import { ErrorResponse } from '../../types';
import { noop } from '../../utils';
import { Callout } from '../Callout/Callout';

import styles from './NotificationsContext.module.scss';

export type Notification = {
  type?: Theme;
  title?: ReactNode | string;
  description?: ReactNode;
  timeout?: number;
  timeDispatched?: number;
};

export type NotificationsContextType = {
  notifications: Notification[];
  addNotification: (notification: Notification) => void;
  removeNotification: (notification: Notification) => void;
  clearNotifications(): void;
};

const initialContextValues = {
  notifications: [],
  clearNotifications: noop,
  removeNotification: noop,
  addNotification: noop,
};

export const NotificationContext = createContext<NotificationsContextType>(initialContextValues);

const getIcon = (type: Theme) => {
  const iconTypes: { [key: string]: IconProp } = {
    success: ['far', 'check'],
    info: ['far', 'lightbulb'],
    warning: 'exclamation-triangle',
    danger: 'exclamation-triangle',
  };
  const icon = iconTypes[type];

  return icon ? <FontAwesomeIcon icon={icon} /> : null;
};

export const NotificationProvider: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [refMap] = useState(() => new WeakMap<Notification, HTMLDivElement>());

  const config = { tension: 125, friction: 20, precision: 0.1 };

  const transitions = useTransition(notifications, {
    from: { opacity: 0, height: 0 },
    enter: (item: Notification) => async (next: (arg0: { opacity: number; height: number }) => void) =>
      await next({
        opacity: 1,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        height: refMap.has(item) ? refMap.get(item).offsetHeight + 27 : 0,
      }),
    leave: { opacity: 0 },
    config,
  });

  const clearNotifications = useCallback(() => setNotifications([]), []);

  const removeNotification = ({ timeDispatched }: Notification) =>
    setNotifications((currentNotifications) =>
      currentNotifications.filter((notification) => notification.timeDispatched !== timeDispatched)
    );

  const addNotification = ({ type = 'danger', timeout = 5000, ...newNotification }: Notification) => {
    const timeDispatched = new Date().getTime();

    setNotifications([...notifications, { ...newNotification, type, timeout, timeDispatched }]);
    setTimeout(() => {
      removeNotification({ timeDispatched });
    }, timeout);
  };

  const contextValue = {
    notifications,
    clearNotifications,
    removeNotification,
    addNotification,
  };

  if (typeof window !== 'undefined') {
    window.notificationContext = contextValue;
  }

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <div className={styles.notifications__container}>
        {transitions((style, item) => {
          const { type = 'danger', title, description, timeDispatched } = item;

          return (
            <animated.div style={style} key={timeDispatched} className={styles.notifications__holder}>
              <Callout icon={getIcon(type)} iconSize="small" theme={type} className={styles.notifications__message}>
                <div
                  ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
                  className={styles.notifications__content}
                >
                  <div className="d-flex mb-1 justify-content-between">
                    <strong className="pr-2">{title}</strong>
                    <button
                      type="button"
                      onClick={() => removeNotification({ timeDispatched })}
                      className={styles['notifications__message__close-btn']}
                    >
                      <FontAwesomeIcon icon={['far', 'times']} />
                    </button>
                  </div>
                  <p className="pr-2">{description}</p>
                </div>
              </Callout>
            </animated.div>
          );
        })}
      </div>
    </NotificationContext.Provider>
  );
};

export const getNotificationContext = (): NotificationsContextType => {
  if (typeof window === 'undefined') {
    return initialContextValues;
  }

  if (!window.notificationContext) {
    throw new Error('No notification context provided');
  }

  return window.notificationContext;
};

export const sendNotification = (notification: Notification) => {
  const { addNotification } = getNotificationContext();

  return addNotification(notification);
};

export const showErrorMessage = (error: ErrorResponse) => {
  process.env.NODE_ENV !== 'test' && console.error(error);

  sendNotification({ title: error.status, description: error.message, type: 'danger' });
};

export const getHttpNotification = (page: string, action: string, type?: Notification['type']) => {
  return {
    title: <Trans i18nKey={`notifications.${page}.${action}.${type}.title`}>Network error</Trans>,
    description: <Trans i18nKey={`notifications.${page}.${action}.${type}.message`}>Unable to perform request</Trans>,
    type,
  };
};

export const sendHttpNotification = (page: string, action: string, type?: Notification['type']) => {
  return requestAnimationFrame(() => {
    sendNotification(getHttpNotification(page, action, type));
  });
};
