import React, { ComponentType, FunctionComponent } from 'react';

import cx from 'clsx';

import { Container } from '../Grid/Container';

import { ActionBarItem } from './components/ActionBarItem';
import { ActionBarPhone } from './components/ActionBarPhone';

import styles from './ActionBar.module.scss';

export type ActionBarProps = {
  LocaleSelector: ComponentType;
};

export const ActionBar: FunctionComponent<ActionBarProps> = ({ LocaleSelector }) => (
  <div className={cx(styles['action-bar'])}>
    <Container className={styles['action-bar__content']}>
      <ActionBarPhone />
      <ActionBarItem>
        <LocaleSelector />
      </ActionBarItem>
    </Container>
  </div>
);
