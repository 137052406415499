import { appSettings } from '@visalex/configs';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { sendHttpNotification, showErrorMessage } from '../components';
import { HTTPQueryProps } from '../types';
import { CLIENT_ERROR_BAD_REQUEST, CLIENT_ERROR_UNAUTHORIZED } from '../utils';

const defaultErrorMessage = { page: 'general', action: 'request' };

/**
 * HTTP Service to perform server requests
 */
class HttpService {
  async query<Entity, Result>({
    method,
    blob,
    headers = {},
    model,
    relativeUrl,
    baseUrl,
    isJson,
    progressCallback,
    isAnon,
    params,
    withCredentials = true,
    showFulfilledNotification = false,
    showErrorNotification = true,
    customErrorMessage,
  }: HTTPQueryProps<Entity>) {
    const token = localStorage.getItem(appSettings.tokenLocalStorageKey);

    const config: AxiosRequestConfig = {
      baseURL: baseUrl || appSettings.baseApiPath,
      url: relativeUrl,
      withCredentials,
      method,
      headers,
      params,
    };

    if (progressCallback) {
      config.onUploadProgress = progressCallback;
    }

    if (isJson) {
      config.transformResponse = JSON.parse;
    }

    if (model) {
      config.data = model;
    }

    if (blob) {
      config.responseType = 'blob';
    }

    if (token && !isAnon) {
      config.headers.Authorization = token;
    }

    try {
      const response = await axios.request<Result>(config);

      if (appSettings.isDevelopment) {
        // Delay requests to simulate real network conditions locally
        await new Promise((resolve) => setTimeout(resolve, 500 + Math.random()));
      }

      if (showFulfilledNotification) {
        sendHttpNotification('general', 'request', 'success');
      }

      return response.data;
    } catch (e) {
      const error = e as AxiosError;

      if (error.response) {
        if (showErrorNotification) {
          if (customErrorMessage) {
            sendHttpNotification(customErrorMessage.page, customErrorMessage.action, 'danger');
          } else if (error.response.status === CLIENT_ERROR_UNAUTHORIZED) {
            sendHttpNotification('http', String(CLIENT_ERROR_UNAUTHORIZED), 'danger');
            window.localStorage.removeItem(appSettings.tokenLocalStorageKey);
          } else if (error.response.data && error.response.data.message) {
            showErrorMessage(error.response.data);
          } else {
            sendHttpNotification(defaultErrorMessage.page, defaultErrorMessage.action, 'danger');
          }
        }

        throw error.response.data;
      } else {
        throw { code: CLIENT_ERROR_BAD_REQUEST };
      }
    }
  }

  get<Entity>(relativeUrl: string, extraOptions: Partial<HTTPQueryProps<Entity>> = {}): Promise<Entity> {
    return this.query({ ...extraOptions, method: 'GET', relativeUrl });
  }

  getLocalJson<Entity>(relativeUrl: string): Promise<Entity> {
    return this.query({ method: 'GET', relativeUrl, baseUrl: '/', isJson: true });
  }

  getLocalBlob<Entity>(relativeUrl: string): Promise<Entity> {
    return this.query({ method: 'GET', relativeUrl, baseUrl: '/', blob: true });
  }

  post<Entity, Result = Entity>(
    relativeUrl: string,
    model: Entity,
    extraOptions: Partial<HTTPQueryProps<Entity>> = {}
  ): Promise<Result> {
    return this.query({ ...extraOptions, method: 'POST', relativeUrl, model });
  }

  put<Entity, Result = Entity>(
    relativeUrl: string,
    model: Entity,
    extraOptions: Partial<HTTPQueryProps<Entity>> = {}
  ): Promise<Result> {
    return this.query({ ...extraOptions, method: 'PUT', relativeUrl, model });
  }

  patch<Entity, Result = Entity>(
    relativeUrl: string,
    model: Entity,
    extraOptions: Partial<HTTPQueryProps<Entity>> = {}
  ): Promise<Result> {
    return this.query({ ...extraOptions, method: 'PATCH', relativeUrl, model });
  }

  delete<Entity, Result = Entity>(
    relativeUrl: string,
    model?: Entity,
    extraOptions: Partial<HTTPQueryProps<Entity>> = {}
  ): Promise<Result> {
    return this.query({ ...extraOptions, method: 'DELETE', relativeUrl, model });
  }
}

export const httpService = new HttpService();
