import React, { Dispatch, ReactNode, SetStateAction } from 'react';

import {
  DropdownOptionList,
  DropdownOptionProps,
  DropdownOptionType,
} from '../Dropdown/SimpleDropdown/DropdownOptionList';
import { SimpleDropdown } from '../Dropdown/SimpleDropdown/SimpleDropdown';

export type LocaleSelectorDropdownProps<ValueType> = {
  show?: boolean;
  onToggle?: Dispatch<SetStateAction<boolean>>;
  title?: string;
  toggleComponent?: ReactNode;
  className?: string;
  options: DropdownOptionType<ValueType>[];
  onSelect: (newOption: DropdownOptionType<ValueType>) => void;
};

export function LocaleOption<ValueType>({ text, imageUrl }: DropdownOptionProps<ValueType>) {
  return (
    <div className="d-flex align-items-center">
      {imageUrl && <img src={imageUrl} alt={text} width={15} height={15} />}
      {text}
    </div>
  );
}

export function LocaleSelectorDropdown<ValueType = string>({
  show,
  onToggle,
  title,
  options,
  onSelect,
  toggleComponent,
  className,
}: LocaleSelectorDropdownProps<ValueType>) {
  return (
    <SimpleDropdown
      alignEnd
      show={show}
      className={className}
      direction="bottom"
      toggleComponent={() => title || toggleComponent}
      onToggle={onToggle}
      focusFirstItemOnShow
      enablePortal
    >
      <DropdownOptionList<ValueType>
        show={show}
        options={options}
        optionsComponent={LocaleOption}
        onSelect={onSelect}
      />
    </SimpleDropdown>
  );
}
