import React from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';

import styles from './Page.module.scss';

export const Page: ComponentWithProps = ({ children, className }) => (
  <div id="page" className={cx(styles.page, className)}>
    <div className={styles.page__content}>{children}</div>
  </div>
);
