import React from 'react';

import { ComponentWithProps } from '../../../types';

import { AccordionSectionProps } from './AccordionSection';

export const AccordionHeader: ComponentWithProps<AccordionSectionProps> = ({ index, header, className }) => (
  <div className={className}>
    <span>{index + 1}</span> {header}
  </div>
);
