import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';

import styles from './Timeline.module.scss';

export type TimelineItem = {
  checked?: boolean;
  dotFilled?: boolean;
  dashFilled?: boolean;
  content: ReactNode | ReactNode[];
  checkIconClassName?: string;
};

export type TimelineProps = {
  label?: string;
  timelineItems?: TimelineItem[];
  dotClassName?: string;
  dashClassName?: string;
  checkIconClassName?: string;
};

export const Timeline: ComponentWithProps<TimelineProps> = ({
  label,
  timelineItems,
  className,
  dotClassName,
  dashClassName,
  checkIconClassName,
}) => {
  return timelineItems && timelineItems.length > 0 ? (
    <div className={className}>
      {label && <h6 className="mb-3">{label}</h6>}
      <div className={cx(styles.timeline)} data-testid="timeline-component">
        {timelineItems.map((item, index) => (
          <React.Fragment key={index}>
            <div className={styles.timeline__mark} data-testid={`timeline-mark-${index}`}>
              <div
                className={cx(styles.timeline__dot, item.dotFilled && styles['timeline__dot--filled'], dotClassName)}
              >
                {item.checked && (
                  <FontAwesomeIcon
                    className={cx(styles.timeline__dot__checked_icon, checkIconClassName, item.checkIconClassName)}
                    icon={['far', 'check']}
                    data-testid={'timeline-check-icon'}
                  />
                )}
              </div>
              {index !== timelineItems.length - 1 && (
                <div
                  className={cx(
                    styles.timeline__dash,
                    item.dashFilled && styles['timeline__dash--filled'],
                    dashClassName
                  )}
                />
              )}
            </div>
            <div className={styles.timeline__content}>{item.content}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  ) : null;
};
