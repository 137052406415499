import React, { forwardRef } from 'react';

import cx from 'clsx';

import { DefaultProps } from '../../../types';
import { Link, LinkProps } from '../../Link/Link';
import { ButtonProps } from '../utils/Button.types';
import { ButtonLoadingCircle } from '../utils/Button.utils';

import styles from '../Button.module.scss';

export type LinkButtonProps = LinkProps & ButtonProps;

export const LinkButton = forwardRef<HTMLAnchorElement, DefaultProps<LinkButtonProps, HTMLAnchorElement>>(
  (
    {
      className,
      theme = 'primary',
      size = '',
      loading = false,
      active = false,
      outline = false,
      block = false,
      icon = false,
      disabled = loading,
      onClick,
      ...anchorProps
    },
    ref
  ) => {
    const classNames = cx(
      styles.btn,
      styles['btn-link'],
      className,
      styles[`btn-${outline ? 'outline-' : ''}${theme !== 'default' ? theme : ''}`],
      active && styles.active,
      active && styles.active,
      block && styles['btn-block'],
      icon && styles['btn-icon'],
      disabled && styles.disabled,
      size && styles[`btn-${size}`]
    );

    if (loading) {
      anchorProps.children = ButtonLoadingCircle(anchorProps);
    }

    if (disabled) {
      anchorProps.href = '';
    }

    return (
      <Link
        {...anchorProps}
        ref={ref}
        className={cx(classNames, disabled && styles.disabled)}
        data-testid="link-button-component"
        onClick={(e) => (disabled ? e.preventDefault() : onClick && onClick(e))}
      >
        {anchorProps.children}
      </Link>
    );
  }
);

LinkButton.displayName = 'LinkButton';
