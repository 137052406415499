import React, { forwardRef, ReactNode } from 'react';

import cx from 'clsx';

import { Container } from '../../Grid/Container';
import { NavBarItemType } from '../NavBar.utils';

import { MenuItems } from './MenuItems';

import styles from '../NavBar.module.scss';

type NavBarDrawerProps = {
  open: boolean;
  onClose(): void;
  header?: ReactNode;
  items: NavBarItemType[];
};

export const NavBarDrawer = forwardRef<HTMLDivElement, NavBarDrawerProps>(({ items, header, open, onClose }, ref) => {
  return (
    <div
      className={cx(styles.nav_bar__drawer, { [styles['nav_bar__drawer--open']]: open })}
      ref={ref}
      onClick={onClose}
    >
      <Container className="pt-3">
        {header && (
          <div className={cx('nav_bar__buttons d-flex d-sm-none justify-content-between mx-3 flex-wrap')}>{header}</div>
        )}
        <MenuItems id="drawer" mobile items={items} />
      </Container>
    </div>
  );
});

NavBarDrawer.displayName = 'NavBarDrawer';
