// Credit https://github.com/react-bootstrap/react-overlays/blob/master/src/DropdownToggle.js

import { FunctionComponent, ReactElement, useContext } from 'react';

import { DropdownContext, DropdownContextType } from './DropdownContext';

/**
 * Wires up Dropdown toggle functionality, returning a set of props to attach
 * to the element that functions as the dropdown toggle (generally a button).
 */
export function useDropdownToggle() {
  const context: DropdownContextType = useContext(DropdownContext);
  const { show, toggle, setToggle } = context;

  return [
    {
      ref: setToggle,
      'aria-haspopup': true,
      'aria-expanded': show,
    },
    { show, toggle },
  ];
}

interface IDropdownToggleRenderProps {
  show: boolean;
  toggle(show: boolean): void;
  props: {
    ref: (popperNode: HTMLElement | null) => void;
    'aria-haspopup': boolean;
    'aria-expanded': boolean;
  };
}

export interface IDropdownToggleProps {
  /**
   * A render prop that returns a Toggle element. The `props`
   * argument should spread through to **a component that can accept a ref**. Use
   * the `onToggle` argument to toggle the menu open or closed
   */
  children(renderProps: IDropdownToggleRenderProps): ReactElement;
}

export const DropdownToggle: FunctionComponent<IDropdownToggleProps> = ({ children }) => {
  const [props, { show, toggle }] = useDropdownToggle();

  return props && props.ref && show && toggle
    ? children({
        show,
        toggle,
        props,
      })
    : null;
};
