import React from 'react';
import { animated } from '@react-spring/web';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { noop } from '../../utils';

import styles from '../Modal/BaseModal/BaseModal.module.scss';

export const Backdrop: ComponentWithProps<{ css?: any; style?: any }> = ({ className, onClick = noop, ...props }) => {
  return (
    <animated.div onClick={(event) => onClick(event)} className={cx(styles.modal__backdrop, className)} {...props} />
  );
};
