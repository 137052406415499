import React, { FunctionComponent, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from 'clsx';

import styles from '../ActionBar.module.scss';

export type ActionBarItemProps = {
  label?: string;
  icon?: 'phone-alt' | 'search';
  withBorder?: boolean;

  children?: ReactNode;
};

export const ActionBarItem: FunctionComponent<ActionBarItemProps> = ({ children, icon, label, withBorder }) => (
  <div className={cx(styles['action-bar__item'], withBorder && styles['action-bar__item_border'])}>
    {icon && <FontAwesomeIcon icon={icon} />}
    {label && <span>{label}</span>}
    {children && <div className="d-inline-block">{children}</div>}
  </div>
);
