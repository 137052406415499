import React, { CSSProperties, memo } from 'react';
import { animated, useSpring } from '@react-spring/web';

import { ComponentWithProps } from '../../types';

import styles from './Animations.module.scss';

export type RevealAnimationProps = {
  /**
   * Controlled state of visibility of the block
   */
  show?: boolean;
  /**
   * Height of the block to be animated
   */
  height: number | string;
  /**
   * Allows to set the offset for animating the block in y-axis
   */
  initialOffset?: number | string;
  /**
   * Allows to prevent height overflow hiding and animate only opacity
   */
  opacityOnly?: boolean;
  /**
   * Extra styles for animation block
   */
  style?: CSSProperties;
};

type SpringProps = { opacity: number; shift: string | number; height: string | number };

export const RevealAnimation: ComponentWithProps<RevealAnimationProps> = memo(
  ({ opacityOnly = false, style, height: elementHeight, initialOffset = 20, show = true, children }) => {
    const { shift, height, ...rest } = useSpring<SpringProps>({
      from: { opacity: 0, shift: initialOffset, height: opacityOnly ? elementHeight : 0 },
      opacity: show ? 1 : 0,
      shift: show ? 0 : initialOffset,
      height: show ? elementHeight : 0,
    });

    return (
      <animated.div
        className={styles['reveal-animation']}
        style={{ ...rest, ...style, height: elementHeight, transform: shift.to((y) => `translateY(${y}px)`) }}
        data-testid="animation-component"
      >
        <animated.div style={{ height }} className={styles['reveal-animation__container']}>
          {children}
        </animated.div>
      </animated.div>
    );
  }
);

RevealAnimation.displayName = 'RevealAnimation';
