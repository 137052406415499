import React, { forwardRef, useEffect, useState } from 'react';

import cx from 'clsx';

import { DefaultProps } from '../../../types';
import { FocusLock } from '../../Other/FocusLock';
import { BaseModal, BaseModalProps } from '../BaseModal/BaseModal';

import styles from './Drawer.module.scss';

export type DrawerWidth = 'extended' | 'full' | 'medium' | 'narrow' | 'wide';
export type DrawerPosition = 'left' | 'right';

export type DrawerProps = Omit<BaseModalProps, 'centered' | 'position'> & {
  /** Controls if the drawer is open or close */
  isOpen: boolean;
  showBackdrop?: boolean;
  /** Available drawer sizes */
  width?: DrawerWidth;
  /** Drawer alignment */
  position?: DrawerPosition;
  focusLockDisabled?: boolean;
  focusLockReturnFocus?: boolean;
};

// Omit default input field size
export const Drawer = forwardRef<HTMLDivElement, Omit<DefaultProps<DrawerProps>, 'size'>>(
  (
    {
      className,
      showBackdrop = true,
      position = 'left',
      width = 'narrow',
      isOpen,
      children,
      focusLockDisabled = false,
      lockScrolling = true,
      focusLockReturnFocus = true,
      autoFocus = false,
      ...modalProps
    },
    ref
  ) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      setMounted(isOpen);
    }, [isOpen]);

    return (
      <BaseModal
        ref={ref}
        isOpen={isOpen}
        className={cx(
          styles.drawer,
          styles[`drawer--${position}`],
          styles[`drawer--${width}`],
          mounted && styles['drawer--open'],
          className
        )}
        size="full-height"
        position={position}
        lockScrolling={lockScrolling}
        showBackdrop={showBackdrop}
        {...modalProps}
        data-testid="drawer-component"
      >
        <aside>
          <FocusLock disabled={focusLockDisabled} returnFocus={focusLockReturnFocus} autoFocus={autoFocus}>
            {children}
          </FocusLock>
        </aside>
      </BaseModal>
    );
  }
);

Drawer.displayName = 'Drawer';
