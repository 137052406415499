import React, { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'visalex-i18next';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { Container } from '../Grid/Container';
import { Link } from '../Link/Link';
import { TextSkeleton } from '../Loaders/Skeleton/TextSkeleton';

import { MenuItems } from './components/MenuItems';
import { NavBarDrawer } from './components/NavBarDrawer';
import { NavBarItemType } from './NavBar.utils';

import styles from './NavBar.module.scss';

export type NavBarProps = {
  items: NavBarItemType[];
  rightSide: ReactNode;
  showRightSideInDrawer?: boolean;
  isLoading?: boolean;
};

export const NavBar: ComponentWithProps<NavBarProps> = ({
  items,
  isLoading = true,
  rightSide,
  showRightSideInDrawer = true,
  children,
  className,
  ...rest
}) => {
  const [open, toggle] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <div {...rest} className={cx(styles.nav_bar__holder, className)} data-testid="nav-bar">
      <nav className={styles.nav_bar}>
        <NavBarDrawer
          open={open}
          items={items}
          onClose={() => toggle(false)}
          header={showRightSideInDrawer ? rightSide : undefined}
        />

        <div className={styles.nav_bar__backdrop}>
          <Container className="align-items-center d-flex justify-content-between">
            <div className={styles.nav_bar__toggle} onClick={() => toggle(!open)}>
              {open ? (
                <img width={24} height={24} src="/icons/icon-close.svg" alt="close" />
              ) : (
                <FontAwesomeIcon icon="bars" width={24} height={24} />
              )}
            </div>
            <div
              className={cx(
                styles.nav_bar__brand,
                'd-flex align-items-center justify-content-between justify-content-md-start'
              )}
            >
              <Link href="/" className={styles.nav_bar__brand__logo}>
                <img src="/images/logo-small.svg" alt={t('general.visalex')} width={135} height={84} />
              </Link>
              {!isLoading ? (
                <MenuItems id="navbar" items={items} />
              ) : (
                !open && (
                  <div className={cx(styles.nav_bar__skeleton, 'd-none d-xl-block')}>
                    {items.map((item) => (
                      <TextSkeleton
                        key={item.link}
                        className={styles.nav_bar__skeleton__item}
                        height={15}
                        width={item.width || 50}
                      />
                    ))}
                  </div>
                )
              )}

              {children}
            </div>

            <div className={cx('nav_bar__buttons d-none d-sm-flex', !showRightSideInDrawer && 'd-flex')}>
              {rightSide}
            </div>
          </Container>
        </div>
      </nav>
    </div>
  );
};
