import React, { CSSProperties } from 'react';

import cx from 'clsx';

import { ComponentWithProps } from '../../../types';

import styles from '../Loaders.module.scss';

export interface Props {
  customSpinnerStyle?: CSSProperties;
  customTextStyle?: CSSProperties;
  text?: string;
}
export const InlineLoader: ComponentWithProps<Props> = ({
  className,
  customSpinnerStyle = { width: 80 },
  customTextStyle = {},
  text = '',
  ...rest
}) => (
  <div {...rest} className={cx(className, styles['inline-spinner'])} data-testid="inline-loader-component">
    <img style={customSpinnerStyle} src="/icons/spinner.svg" />
    <span style={customTextStyle}>{text}</span>
  </div>
);
