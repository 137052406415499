import { useCallback, useState } from 'react';

export const useBlock = (initialState: number[] = []) => {
  const [blocksIds, changeBlockIds] = useState<number[]>(initialState);

  const addBlock = useCallback(() => {
    const length = blocksIds.length ? blocksIds.length - 1 : 0;
    const max = [...blocksIds].sort((a, b) => b - a)[0] || length;

    changeBlockIds((questions) => [...questions, max + 1]);
  }, [blocksIds]);

  const removeBlock = useCallback(
    (blockId: number) => {
      changeBlockIds((questions) => questions.filter((id) => id !== blockId));
    },
    [blocksIds]
  );

  return { blocksIds, changeBlockIds, addBlock, removeBlock };
};
