import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { nanoid } from 'nanoid';

import { ComponentWithProps } from '../../types';
import { Avatar } from '../Avatar/Avatar';
import { Skeleton } from '../Loaders/Skeleton/Skeleton';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './UserList.module.scss';

export type UserListProps = {
  // TODO: Move to @visalex/shared
  users: any[];
  width?: number;

  /**
   *  How many users should be visible
   *  @default 3
   *  */
  userCount?: number;

  /**
   *  How many loading skeletons of users should be visible
   *  @default 4
   *  */
  skeletonsCount?: number;

  isLoading?: boolean;

  openUserProfileOnClick?: boolean;
};

const rolePriority: Record<string, number> = {
  ROLE_ATTORNEY: 5,
  ROLE_IMMIGRATION_SPECIALIST: 4,
  ROLE_BENEFICIARY: 3,
  ROLE_PETITIONER: 2,
  ROLE_DEPENDENT: 1,
};

export const UserList: ComponentWithProps<UserListProps> = ({
  users,
  width = 27,
  userCount = 3,
  skeletonsCount = 4,
  isLoading = false,
  openUserProfileOnClick,
}) => {
  const { t } = useTranslation();

  const handleProfileRedirect = (id: string, userType: any) => {
    window.open(`/${userType === 'ApiModelClient' ? 'clients' : 'employees'}/${id}`, '_blank');
  };

  const userListMarkup = useMemo(() => {
    const result = [];
    let usersEnd = users.length;

    const usersByPriority = Array.from(users).sort((a, b) => rolePriority[b.role.name] - rolePriority[a.role.name]);

    if (users && users.length !== 0) {
      usersEnd = users.length > userCount ? userCount : users.length;
    }

    for (let i = 0; i < usersEnd; i++) {
      const currentUser = usersByPriority[i];

      result.push(
        <Tooltip
          key={nanoid()}
          tooltipContent={
            <>
              <strong>
                {currentUser.firstName} {currentUser.lastName}
              </strong>
              {currentUser.role && (
                <>
                  <br />
                  {t(`case.role.${currentUser.role?.name}`)}
                </>
              )}
            </>
          }
          enablePortal
          darkTheme
          toggleClassName={styles.users__tooltip__toggle}
          className={styles.users__tooltip}
          onClick={() => openUserProfileOnClick && handleProfileRedirect(currentUser.id, currentUser.userType)}
        >
          <Avatar
            width={width}
            firstName={currentUser.firstName}
            lastName={currentUser.lastName}
            avatarUrl={currentUser.avatarPreviewUrl}
          />
        </Tooltip>
      );
    }

    return result;
  }, [users]);

  return (
    <div className={styles.users}>
      {isLoading ? (
        new Array(skeletonsCount)
          .fill('mock')
          .map((_, i) => <Skeleton key={i} width={width} height={width} className={styles.users__number_skeleton} />)
      ) : users && users.length !== 0 ? (
        <>
          {userListMarkup}
          {users.length > userCount ? (
            <div style={{ width, height: width }} className={styles.users__number}>
              <span>+{users.length - userCount}</span>
            </div>
          ) : null}
        </>
      ) : (
        <span className={styles.users__dash}>—</span>
      )}
    </div>
  );
};
