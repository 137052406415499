import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { convertUrl } from '../../utils';
import { Link, LinkProps } from '../Link/Link';

import styles from './BreadCrumbs.module.scss';

export type BreadCrumb = LinkProps & { breadcrumb: string };

export type BreadCrumbsProps = {
  breadcrumbs: BreadCrumb[];
  removeDash?: boolean;
};

export const BreadCrumbs: ComponentWithProps<BreadCrumbsProps> = ({ breadcrumbs, removeDash = false, className }) => {
  return (
    <div className={cx(styles.breadcrumbs, className)}>
      {breadcrumbs.map((breadcrumb: BreadCrumb, index: number) => {
        if (index !== breadcrumbs.length - 1) {
          return (
            <Fragment key={breadcrumb.href}>
              <Link {...breadcrumb}>{removeDash ? convertUrl(breadcrumb.breadcrumb) : breadcrumb.breadcrumb}</Link>
              <FontAwesomeIcon icon={['far', 'chevron-right']} />
            </Fragment>
          );
        }

        return (
          <span key={breadcrumb.href} {...(breadcrumb as any)}>
            {removeDash ? convertUrl(breadcrumb.breadcrumb) : breadcrumb.breadcrumb}
          </span>
        );
      })}
    </div>
  );
};

BreadCrumbs.displayName = 'BreadCrumbs';
