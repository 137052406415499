import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from 'clsx';

import { ComponentWithProps } from '../../../../types';
import { Button } from '../../../Button/Button';
import { DrawerPosition } from '../Drawer';

import styles from '../Drawer.module.scss';

export type DrawerCloserProps = {
  onClose(): void;
  alt?: string;
  position?: DrawerPosition;
};

export const DrawerCloser: ComponentWithProps<DrawerCloserProps> = ({
  className,
  onClose,
  position = 'left',
  alt = 'Close drawer',
}) => (
  <div className={cx(className, styles.drawer__closer)}>
    <Button icon theme="default" onClick={onClose} aria-label="Close">
      <FontAwesomeIcon
        icon={['far', 'arrow-left']}
        style={{ width: 18, height: 18 }}
        flip={position === 'right' ? 'horizontal' : undefined}
      />
      <span className="sr-only">{alt}</span>
    </Button>
  </div>
);
