import React, { ComponentProps, forwardRef, PropsWithChildren } from 'react';
import { DayPicker as DatePicker } from 'react-day-picker';

import cx from 'clsx';

import { mixClassNames } from '../../lib/mixClassNames';

import styles from './DayPicker.module.scss';

export const DayPicker = forwardRef<HTMLDivElement, PropsWithChildren<ComponentProps<typeof DatePicker>>>(
  ({ className, children, ...props }, ref) => (
    <div className={cx(styles.root, className)} ref={ref}>
      {children}
      <DatePicker
        {...props}
        classNames={mixClassNames(props.classNames)}
        className={styles['root--dayPicker']}
        modifiersClassNames={{
          ...props.modifiersClassNames,
          selected: cx(styles['modifiers--selected'], props.modifiersClassNames?.selected),
        }}
      />
    </div>
  )
);

DayPicker.displayName = 'DayPicker';
