import React, { useRef } from 'react';
import { animated, to, useSpring } from '@react-spring/web';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { Container } from '../Grid/Container';

import styles from './Hero.module.scss';

const calcParallax = (x: number, y: number) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const bgParallax = (x: number, y: number) => `translate3d(${-x / 55}px,${-y / 55}px,0)`;

const calcContainer = (x: number, y: number, rect: ClientRect) => [
  -(y - rect.top - rect.height / 2) / 1000,
  (x - rect.left - rect.width / 2) / 1000,
];
const containerParallax = (x: number, y: number) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg)`;

export type HeroProps = {
  isAnimationEnabled?: boolean;
};

export const Hero: ComponentWithProps<HeroProps> = ({ children, className, isAnimationEnabled = true }) => {
  const ref = useRef<HTMLElement>(null);
  const [{ xy }, setParallax] = useSpring<{ xy: number[] }>(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const [{ xyContainer }, set] = useSpring<{ xyContainer: number[] }>(() => ({
    xyContainer: [0, 0],
    config: { mass: 13, tension: 150, friction: 80 },
  }));

  return (
    <section
      ref={ref}
      className={cx(className, styles.hero, !isAnimationEnabled && styles['hero--without-animation'])}
      onMouseLeave={() => set({ xyContainer: [0, 0] })}
      onMouseMove={({ clientX: x, clientY: y }) => {
        if (isAnimationEnabled) {
          setParallax({ xy: calcParallax(x, y) });

          if (ref && ref.current) {
            const rect = ref.current.getBoundingClientRect();

            set({ xyContainer: calcContainer(x, y, rect) });
          }
        }
      }}
      data-testid="hero-section"
    >
      <Container className={styles.hero__container}>
        <animated.div
          className={styles.hero__content}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={{ transform: to(xyContainer, containerParallax) }}
        >
          {children}
        </animated.div>
      </Container>
      <animated.div
        className={cx('hero__background', styles.hero__background)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={{ transform: to(xy, bgParallax) }}
      />
    </section>
  );
};
