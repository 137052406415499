import React, { ReactNode } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@visalex/configs';

import cx from 'clsx';

import { ComponentWithProps } from '../../types';
import { Button } from '../Button/Button';
import {
  DropdownOptionList,
  DropdownOptionProps,
  DropdownOptionType,
} from '../Dropdown/SimpleDropdown/DropdownOptionList';
import { SimpleDropdown, SimpleDropdownProps } from '../Dropdown/SimpleDropdown/SimpleDropdown';

import styles from './DropdownButtonSelect.module.scss';

const ConditionOption = ({ text }: DropdownOptionProps<string>) => (
  <div className={styles['condition-select__option']}>{text}</div>
);

export type FieldConditionSelectProps = SimpleDropdownProps & {
  prefix?: ReactNode;
  options: DropdownOptionType[];
  iconName?: IconName;
  isInvalid?: boolean;
  toggleButtonClassName?: string;
  dataTestId?: string;
  onSelect: (newValue: DropdownOptionType) => void;
  outline?: boolean;
  theme?: Theme;
};

export const DropdownButtonSelect: ComponentWithProps<FieldConditionSelectProps> = ({
  prefix,
  options,
  iconName,
  onSelect,
  children,
  className,
  toggleButtonClassName,
  isInvalid = false,
  menuClassName,
  disabled,
  dataTestId,
  theme,
  outline = true,
  ...rest
}) => {
  return (
    <div
      className={cx('d-flex align-items-center', className, disabled && [styles['condition-select--disabled']])}
      data-testid={dataTestId}
    >
      {prefix && <span className={styles['condition-select__prefix']}>{prefix}</span>}
      <SimpleDropdown
        direction="bottom"
        fullWidthMenu
        hideOnClickInside
        enablePortal
        toggleComponent={() => (
          <Button
            outline={outline}
            data-invalid={isInvalid}
            theme={theme ? theme : isInvalid ? 'danger' : 'secondary'}
            className={cx(styles['condition-select__button'], toggleButtonClassName)}
          >
            {iconName ? <FontAwesomeIcon icon={['far', iconName]} /> : null}
            {children}
          </Button>
        )}
        toggleClassName={styles['condition-select__toggle']}
        menuClassName={cx(styles['condition-select__menu'], menuClassName)}
        disabled={disabled}
        {...rest}
      >
        <DropdownOptionList options={options} optionsComponent={ConditionOption} onSelect={onSelect} />
      </SimpleDropdown>
    </div>
  );
};
