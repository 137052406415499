import React, { ReactNode } from 'react';
import { Theme } from '@visalex/configs';

import cx from 'clsx';

import { Callout, CalloutProps } from '../Callout/Callout';

import styles from './DangerCallout.module.scss';

export type DangerCalloutProps = CalloutProps & {
  header: ReactNode;
  theme?: Theme;
  paragraph: ReactNode;
};

export const DangerCallout = ({ header, theme = 'danger', paragraph, className, ...rest }: DangerCalloutProps) => {
  return (
    <Callout
      {...rest}
      iconSize="small"
      icon="❗️"
      theme={theme ? theme : 'danger'}
      className={cx(className, styles['danger-zone'], styles[`danger-zone--${theme}`])}
    >
      <div className={styles['danger-zone__heading']}>{header}</div>
      <div className={styles['danger-zone__content']}>{paragraph}</div>
    </Callout>
  );
};
