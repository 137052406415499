import React, { forwardRef } from 'react';

import cx from 'clsx';

import { DefaultProps } from '../../types';

type DeviceSize = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
const DEVICE_SIZES: DeviceSize[] = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];

type IColumnSize = number | { span?: 'auto' | number | true; offset?: number; order?: number };

export type ColProps = {
  // The number of columns to span on extra small devices (< 576px)
  xs?: IColumnSize;

  // The number of columns to span on small devices (≥ 576px)
  sm?: IColumnSize;

  // The number of columns to span on medium devices (≥ 768px)
  md?: IColumnSize;

  // The number of columns to span on large devices (≥ 992px)
  lg?: IColumnSize;

  // The number of columns to span on extra large devices (≥ 1200px)
  xl?: IColumnSize;

  // The number of columns to span on extra large devices (≥ 1400px)
  xxl?: IColumnSize;
};

export const Col = forwardRef<HTMLDivElement, DefaultProps<ColProps>>(({ className, children, ...props }, ref) => {
  const spans: string[] = [];
  const classes: string[] = [];

  DEVICE_SIZES.forEach((breakPoint: DeviceSize) => {
    const propValue = props[breakPoint];

    delete props[breakPoint];

    let span;
    let offset;
    let order;

    if (propValue != null && typeof propValue === 'object') {
      ({ span = true, offset = 0, order = 0 } = propValue);
    } else {
      span = propValue;
    }

    const infix = breakPoint !== 'xs' ? `${breakPoint}-` : '';

    if (span != null) spans.push(span === true ? `col-${infix}` : `col-${infix}${span}`);

    if (order != null) classes.push(`order-${infix}${order}`);

    if (offset != null) classes.push(`offset-${infix}${offset}`);
  });

  return (
    <div ref={ref} {...props} className={cx('col', className, ...spans, ...classes)}>
      {children}
    </div>
  );
});

Col.displayName = 'Col';
