import React from 'react';
import noop from 'lodash/noop';
import { StoryFn } from '@storybook/react';
import { useTranslation } from 'visalex-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { SubmitHandler, UseFormProps } from 'react-hook-form/dist/types/form';

import { Button } from '../../components';

import styles from './FormDecorator.module.scss';

type FormDecoratorProps = {
  Story: StoryFn;
  useFormProps?: UseFormProps;
  onSubmit?: SubmitHandler<FieldValues>;
};

export function FormDecorator({ Story, useFormProps, onSubmit = noop }: FormDecoratorProps) {
  const { t } = useTranslation();
  const context = useForm(useFormProps);

  return (
    <FormProvider {...context}>
      <form onSubmit={context.handleSubmit(onSubmit)} onReset={() => context.reset()}>
        <Story />
        <div className={styles.form__controls}>
          <Button theme="primary" type="reset" outline className={styles.form__controls__button}>
            {t('profile.controls.cancel')}
          </Button>
          <Button type="submit" className={styles.form__controls__button}>
            {t('profile.controls.save')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
