import React, { AnchorHTMLAttributes, forwardRef, ReactNode } from 'react';
import { Link as RouterLink } from 'visalex-router';

import cx from 'clsx';

import styles from './Link.module.scss';

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  /**
   * react-router-dom link replace
   */
  replace?: boolean;
  external?: boolean;
  styled?: boolean;
};

/**
 * This is the shared link between next.js and regular react app
 * so it requires a special package substitution to make it reusable
 * across both applications.
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, external = false, href = '#', styled = false, children, ...anchorProps }, ref) => {
    const classNames = cx(className, styled && styles.link);

    if (external || href?.includes('://')) {
      return (
        <a {...anchorProps} ref={ref} className={classNames} href={href}>
          {children}
        </a>
      );
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <RouterLink {...anchorProps} ref={ref} to={href} href={href} className={classNames}>
        {children as ReactNode}
      </RouterLink>
    );
  }
);

Link.displayName = 'Link';
