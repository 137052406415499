import React from 'react';
import { StoryFn } from '@storybook/react';

import { Pane } from '../components';

export function PannedDecorator(StoryComponent: StoryFn) {
  return (
    <Pane>
      <StoryComponent />
    </Pane>
  );
}
