import React, { FunctionComponent, useMemo } from 'react';
import { Col, Container, Link, Row } from '@visalex/core';

import cx from 'clsx';
import { TFunction } from 'i18next';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { getFooterLinks, getTermsAndPrivacyLinks } from './footerMenuItems';

import styles from './Footer.module.scss';

type FooterSectionLinksProps = {
  language: string;
  listKey: number;
  links: Array<{ text: string; link: string; external?: boolean }>;
};

const FooterSectionLinks: FunctionComponent<FooterSectionLinksProps> = ({ listKey, links }) => (
  <>
    {links.map((linkItem, itemKey) => (
      <Link
        className={styles['footer__section-link']}
        href={`${linkItem.link}`}
        key={`footer-section__${listKey}_${itemKey}`}
      >
        {linkItem.text}
      </Link>
    ))}
  </>
);

const SocialIcons: FunctionComponent<{ t: TFunction }> = ({ t }) => (
  <div className={styles['social-icons']}>
    <a href="https://www.facebook.com/visalexllc/">
      <Image
        src="/icons/footer/icon-social-fb.svg"
        alt={t('footer.get-in-touch.imageAlt.facebook')}
        width={21}
        height={21}
      />
      <span>{t('footer.get-in-touch.follow')}</span>
    </a>
    <a href="https://www.instagram.com/visalex_llc/">
      <Image
        src="/icons/footer/icon-social-ig.svg"
        alt={t('footer.get-in-touch.imageAlt.instagram')}
        width={21}
        height={21}
      />
      <span>{t('footer.get-in-touch.follow')}</span>
    </a>
    <a href="https://twitter.com/VisaLexLLC">
      <Image
        src="/icons/footer/icon-social-tw.svg"
        alt={t('footer.get-in-touch.imageAlt.twitter')}
        width={21}
        height={21}
      />
      <span>{t('footer.get-in-touch.follow')}</span>
    </a>
  </div>
);

const Address: FunctionComponent<{ t: TFunction }> = ({ t }) => (
  <div className={styles.address}>
    <>
      {t('footer.address.0')}
      {'\n'}
      {t('footer.address.1')}
      {'\n'}
      {t('footer.address.2')}
    </>
  </div>
);
const Copyright: FunctionComponent<{ t: TFunction }> = ({ t }) => (
  <div className={styles.copyright}>
    <>
      {t('footer.copyright.0')} © {new Date().getFullYear()} {t('footer.copyright.1')}
      {'\n'}
      {t('footer.copyright.2')}
    </>
  </div>
);

const Footer: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const footerLinks = useMemo(() => getFooterLinks(t), [i18n.language]);
  const TermsAndPrivacyLinks = useMemo(() => getTermsAndPrivacyLinks(t), [i18n.language]);

  return (
    <footer className={styles.footer} data-testid="footer">
      <Container>
        <Row>
          {footerLinks.map((section, key) => {
            return (
              <Col
                key={`footer-section__${key}`}
                xs={section.sm}
                md={section.md}
                lg={section.lg}
                className={styles[`footer__column-${key}`]}
              >
                {section.headerText && section.headerLink ? (
                  <a href={section.headerLink} className={styles['footer__section-header']}>
                    {section.headerText}
                  </a>
                ) : section.headerText && !section.headerLink ? (
                  <span className={styles['footer__section-header']}>{section.headerText}</span>
                ) : null}
                <FooterSectionLinks language={i18n.language} listKey={key} links={section.links} />
                {key === 3 && (
                  <div className="d-none d-lg-block">
                    <SocialIcons t={t} />
                  </div>
                )}
              </Col>
            );
          })}
          <Col lg={1} md={3} sm={12} className={cx(styles[`footer__column-${footerLinks.length}`], 'd-lg-none')}>
            <div className="d-lg-none">
              <SocialIcons t={t} />
            </div>
          </Col>

          <Col lg={4} md={9} sm={12} className={styles[`footer__column-${footerLinks.length + 1}`]}>
            <div className={cx(styles['footer__terms-links'], styles['hide-on-md'])}>
              <FooterSectionLinks language={i18n.language} listKey={0} links={TermsAndPrivacyLinks} />
            </div>
            <div className={styles.footer__content}>
              {t('footer.disclaimer')}
              <Link href="https://www.uscis.gov" className={styles['footer__section-link']}>
                www.uscis.gov
              </Link>
              .
            </div>
            <div className="d-none d-md-block d-lg-none">
              <Address t={t} />
            </div>
            <div className={styles['hide-on-md']}>
              <Address t={t} />
              <Copyright t={t} />
            </div>
          </Col>
          <Col md={3} sm={12} className={cx(styles[`footer__column-${footerLinks.length + 2}`])}>
            <div className={styles['footer__terms-links']}>
              <FooterSectionLinks language={i18n.language} listKey={0} links={TermsAndPrivacyLinks} />
            </div>
            <Copyright t={t} />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
