import { useEffect, useState } from 'react';

export function useMedia<V = number>(queries: string[], values: V[], defaultValue: V) {
  if (typeof window !== 'undefined') {
    const match = () => values[queries.findIndex((q) => window.matchMedia(q).matches)] || defaultValue;
    const [value, set] = useState(match);

    useEffect(() => {
      const handler = () => set(match);

      window.addEventListener('resize', handler);

      return () => window.removeEventListener('resize', handler);
    }, []);

    return value;
  }

  return defaultValue;
}
